import React from 'react';
import { Form } from 'semantic-ui-react';
import { Field, GenericField } from 'redux-form';

import * as T from './_textAreaTypes';

const View: React.FC<T.FormTextArea> = (props: any) => (
	<Form.TextArea
		value={props.input.value}
		onChange={(_: any, { value }: any) => props.input.onChange(value)}
		error={props.meta.touched && props.meta.error}
		{...props}
	/>
);
export const TextArea = View;

export const FormTextArea = Field as new () => GenericField<T.FormTextArea>;
