import React from 'react';
import MediaQuery from 'react-responsive';
import { withTranslation } from 'react-i18next';
import { get, isArray } from 'lodash-es';
import { Header, Grid, Segment, Divider, Image } from 'semantic-ui-react';
import { compose, lifecycle, withState, withStateHandlers } from 'recompose';

import * as T from 'modules/facilities/_facilitiesTypes';
import { Facility } from 'modules/envelopes/_envelopesTypes';
import { getAllFacilities } from 'modules/facilities/_facilitiesActions';

import * as c from 'utils/constants';

import { Confirm } from './Confirm';

const enhance = compose(
	withTranslation('Facilities'),
	withState<any, Facility[], string, string>(
		'facilities',
		'fetchFacilities',
		[],
	),
	lifecycle<any, any>({
		async componentDidMount() {
			const [facilitiesResponse] = await Promise.all([getAllFacilities()]);
			if (isArray(get(facilitiesResponse, 'data.rows'))) {
				this.props.fetchFacilities(
					get(facilitiesResponse, 'data.rows', []).map(
						(facility: T.Facility) => ({
							...facility,
							validity: 'implement me',
						}),
					),
				);
			}
		},
	}),
	withStateHandlers<any, any, any>(
		{ dialog: null, facility: null },
		{
			openDialog: () => (type: any, facility: any) => ({
				dialog: type,
				facility,
			}),
		},
	),
);

const View: React.FC<any> = ({
	t,
	facilities,
	openDialog,
	dialog,
	facility,
}) => (
	<>
		<Segment vertical>
			<Grid celled={false} padded="horizontally">
				<MediaQuery minWidth={c.BREAKPOINTS.desktopMin}>
					<Divider hidden />
				</MediaQuery>

				<Grid.Row>
					<Grid.Column tablet={16} computer={6}>
						<Header as="h1">{t('FACILITIES')}</Header>
						<MediaQuery maxWidth={c.BREAKPOINTS.tabletMax}>
							<Divider hidden />
						</MediaQuery>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<Grid>
				<Grid.Column mobile={16} tablet={8} computer={4}>
					<Image src="https://images.unsplash.com/photo-1562101806-ecd80e69bbb7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80" />
				</Grid.Column>
				<Grid.Column mobile={16} tablet={8} computer={4}>
					<Image src="https://images.unsplash.com/photo-1562101806-ecd80e69bbb7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80" />
				</Grid.Column>
				<Grid.Column mobile={16} tablet={8} computer={4}>
					<Image src="https://images.unsplash.com/photo-1562101806-ecd80e69bbb7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80" />
				</Grid.Column>
				<Grid.Column mobile={16} tablet={8} computer={4}>
					<Image src="https://images.unsplash.com/photo-1562101806-ecd80e69bbb7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80" />
				</Grid.Column>
				<Grid.Column mobile={16} tablet={8} computer={4}>
					<Image src="https://images.unsplash.com/photo-1562101806-ecd80e69bbb7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80" />
				</Grid.Column>
			</Grid>
		</Segment>
		{dialog === 'confirm' && (
			<Confirm
				text={`${t('CONFIRM_DELETE_FACILITY_TEXT')} ${facility.name}?`}
			/>
		)}
	</>
);

export const Facilities = enhance(View);
