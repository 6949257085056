import React from 'react';
import { Form } from 'semantic-ui-react';
import { Field, GenericField } from 'redux-form';

import { Flex } from 'components/styled';

import * as T from './_inputTypes';

const View: React.FC<T.FormInput> = (props: any) => (
	<Flex
		flexWrap={props.errorTextPosition === 'bottom' ? 'wrap' : 'nowrap'}
		flexDirection={props.errorTextPosition === 'bottom' ? 'column' : 'row'}
		alignItems={props.errorTextPosition === 'bottom' ? null : 'center'}
	>
		<Form.Input
			fluid={props.errorTextPosition === 'bottom' ? true : false}
			icon="lock"
			iconPosition="left"
			type="password"
			error={props.meta.touched && props.meta.error ? true : false}
			{...props}
		/>
		<span
			style={{
				marginLeft: props.errorTextPosition === 'bottom' ? 0 : 10,
				marginTop: props.errorTextPosition === 'bottom' ? 0 : 10,
				marginBottom: props.errorTextPosition === 'bottom' ? 15 : 0,
				color: '#9f3a38',
				display: props.meta.touched && props.meta.error ? 'block' : 'none',
			}}
		>
			{props.meta.touched && props.meta.error}
		</span>
	</Flex>
);
export const Password = View;

export const FormPassword = Field as new () => GenericField<T.FormInput>;
