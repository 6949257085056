/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import MediaQuery from 'react-responsive';
import { get, find, isEmpty } from 'lodash-es';
import { withRouter, Link, NavLink } from 'react-router-dom';
import { Image, Menu, Icon, Dropdown } from 'semantic-ui-react';
import { compose, withHandlers, withProps } from 'recompose';
import { useTranslation, withTranslation } from 'react-i18next';

import { logoutUser } from 'modules/login/_loginActions';

import logo from 'assets/img/sareza.svg';

import * as storage from 'utils/storage';
import { hasAuthority } from 'utils/utils';
import * as c from 'utils/constants';
import style from 'utils/style';
import menuItems from 'utils/constants/menuItems';

import * as T from './_appbartypes';

const enhance = compose<any, any>(
	withRouter,
	withTranslation('Appbar'),
	withHandlers<any, any>({
		handleLogout: ({ history }: any) => async () => {
			await logoutUser();
			history.push('/');
			window.location.reload();
		},
	}),
	withProps<any, any>({ user: JSON.parse(storage.get('token') ?? '{}') }),
	withProps<any, any>(({ authorities }: any) => ({
		role: get(authorities, '[0]', false),
	})),
	withProps<any, any>(({ authorities, role }: any) => ({
		roleName: get(authorities, '[0]', false)
			? find(c.ROLES, { key: role })
			: false,
	})),
);

const View: React.FC<T.Appbar> = ({
	user,
	handleLogout,
	toggleSidebar,
	sidebarOpened,
}) => {
	const { t } = useTranslation('Appbar');
	return (
		<Menu
			fixed="top"
			inverted
			size="small"
			css={css`
				height: 4.5rem;
				& .item:before {
					background: transparent !important;
				}
				& .item.active {
					background: #00244c;
				}
			`}
		>
			<MediaQuery minWidth={c.BREAKPOINTS.desktopMin}>
				<Menu.Item
					as={Link}
					to={!isEmpty(user) ? '/account/envelopes' : '/'}
					header
					css={css`
						width: 260px;
					`}
				>
					<Image
						alt={t('SAREZA')}
						src={logo}
						css={css`
							width: 30px;
							height: 100%;
							margin: 0 0.35714286em 0 0;
						`}
					/>
					{t('SAREZA')}
				</Menu.Item>
			</MediaQuery>
			{!isEmpty(user) && (
				<MediaQuery maxWidth={c.BREAKPOINTS.tabletMax}>
					<Menu.Item
						name="menu"
						style={{ textTransform: 'uppercase', border: 0 }}
						onClick={() => toggleSidebar(!sidebarOpened)}
					>
						<Icon name="bars" size="large" />
						Menu
					</Menu.Item>
				</MediaQuery>
			)}

			{!isEmpty(user) ? (
				<Fragment>
					<MediaQuery minWidth={c.BREAKPOINTS.desktopMin}>
						<Menu.Menu
							css={css`
								padding-left: 2em;
								padding-right: 2em;
							`}
						>
							{menuItems.map((item: any) =>
								!item.restriction ||
								(item.restriction && hasAuthority(item.restriction)) ? (
									item.submenu ? (
										<Dropdown
											key={item.link}
											item
											simple
											text={t(item.label)}
											style={{ textTransform: 'uppercase', border: 0 }}
										>
											<Dropdown.Menu>
												{item.submenu.map((submenuItem: any) => (
													<Dropdown.Item
														key={submenuItem.link}
														as={NavLink}
														to={submenuItem.link}
													>
														{t(submenuItem.label)}
													</Dropdown.Item>
												))}
											</Dropdown.Menu>
										</Dropdown>
									) : (
										<Menu.Item
											key={item.link}
											as={NavLink}
											to={item.link}
											style={{ textTransform: 'uppercase', border: 0 }}
										>
											{t(item.label)}
										</Menu.Item>
									)
								) : null,
							)}
						</Menu.Menu>
					</MediaQuery>
					<Menu.Menu position="right">
						<Menu.Item>
							<Link to="/user/edit">
								<Icon name="user outline" size="big" />
							</Link>
							<div style={style('appBar.user.overview')}>
								<MediaQuery minWidth={c.BREAKPOINTS.desktopMin}>
									<Link style={style('appBar.user.name')} to="/user/edit">
										{user.lastName ? (
											<div>
												{user.firstName} {user.lastName}
											</div>
										) : (
											<div>{user.email}</div>
										)}
									</Link>
									<div style={style('appBar.user.role')}>
										({get(user, 'roleName.name', t('CLIENT'))})
									</div>
								</MediaQuery>
								<div onClick={handleLogout} style={style('appBar.user.logout')}>
									{t('LOGOUT')}
								</div>
							</div>
						</Menu.Item>
					</Menu.Menu>
				</Fragment>
			) : (
				<Menu.Menu position="right">
					<Menu.Item as={Link} to="/login">
						<Icon name="user outline" size="big" />
						{t('LOGIN')} / {t('REGISTER')}
					</Menu.Item>
				</Menu.Menu>
			)}
		</Menu>
	);
};

export const Appbar = enhance(View);
