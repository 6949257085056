import { get } from 'lodash-es';

import { RegistrationMessages } from 'modules/registration/_registrationTypes';

import { fetch } from 'utils/utils';
import { formHasError } from 'utils/form';

const registerUser = async (
	data: object,
): Promise<{
	hasErrors: boolean;
	hasData: boolean;
	msgs: RegistrationMessages;
}> => {
	try {
		const response = await fetch(`/sarezaWeb/api/ns/registration`, {
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/json' }),
			body: JSON.stringify({
				...data,
			}),
		});

		const hasErrors = formHasError(response);
		const hasData = get(response, 'data', null) ? true : false;
		return {
			hasErrors,
			hasData,
			msgs: get<RegistrationMessages>(response, 'msgs', []),
		};
	} catch (err) {
		return {
			hasErrors: true,
			hasData: false,
			msgs: [],
		};
	}
};

const confirmRegistration = async (token: string): Promise<any> => {
	try {
		const response = await fetch(
			`/sarezaWeb/api/ns/confirmRegistration?token=${token}`,
			{
				method: 'GET',
				headers: new Headers({ 'Content-Type': 'application/json' }),
			},
		);

		const hasErrors = formHasError(response);

		if (hasErrors) {
			return false;
		} else {
			return true;
		}
	} catch (err) {
		return false;
	}
};

export { registerUser, confirmRegistration };
