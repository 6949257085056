const CURRENCIES = {
	CZK: 'Kč',
};

export default {
	Appbar: {
		SAREZA: 'SAREZA portál',
		PROFILE: 'Účet',
		CARDS: 'Karty',
		NEWS: 'Aktuality',
		ADMINISTRATION: 'Administrace',
		LOGOUT: 'ODHLÁSIT',
		LOGIN: 'Přihlásit',
		REGISTER: 'Registrovat',
		CLIENT: 'Klient',
		ADMINISTRATOR: 'Administrátor',
		OVERVIEW: 'Přehled',
		TRANSACTIONS: 'Transakce',
		HISTORY: 'Historie',
		TREND: 'Vývoj',
		ALL_USERS: 'Přehled uživatelů',
		ALL_CARDS: 'Přehled karet',
		ALL_ACTIVITIES: 'Přehled aktivit',
		USER_PROFILE: 'Uživatelský profil',
		CHANGE_PASSWORD: 'Změnit heslo',
		USER: 'Uživatel',
		FACILITIES: 'Areály',
	},
	Profile: {
		// Change password form
		CHANGE_PASSWORD: 'Změna hesla',
		OLD_PASSWORD: 'Staré heslo',
		NEW_PASSWORD: 'Nové heslo',
		NEW_PASSWORD_AGAIN: 'Nové heslo (znovu)',
		SAVE_PASSWORD: 'Uložit heslo',
		SAVE_PROFILE: 'Uložit profil',
		USER_PROFILE: 'Uživatelský profil',
		SAME_ADDRESS: 'Adresa je shodná s adresou bydliště',
		'system.error.passwordsDoNotMatch': 'Hesla se nezhodují',
		'system.error.wrongOldPassword': 'Nesprávně zadané staré heslo',
		'system.info.changePasswordCompleted': 'Heslo úspěšně změněno',

		// Tabs
		BASIC_INFO: 'Základní informace',
		ADDRESS: 'Bydliště',
		DELIVERY_ADDRESS: 'Doručovací adresa',

		// Profile basic form
		LOGIN: 'Přihlašovací jméno',
		TITLE_BEFORE: 'Titul před',
		TITLE_AFTER: 'Titul za',
		FIRST_NAME: 'Jméno',
		MIDDLE_NAME: 'Prostřední jméno',
		LAST_NAME: 'Příjmení',
		BIRTHDATE: 'Datum narození',
		PHONE: 'Telefon',
		EMAIL: 'E-mail',
		COMPANY: 'Firma',
		ICO: 'Ičo',
		DIC: 'Dič',

		// ADDRESS
		STREET: 'Ulice',
		STREET_NUMBER_DESCRIPTIVE: 'Číslo popisné',
		STREET_NUMBER_ORIENTATION: 'Číslo orientační',
		CITY: 'Město',
		ZIP: 'PSČ',
		COUNTRY: 'Země',

		'system.info.updateSuccess': 'Profil úspěšně upraven',
	},
	Cards: {
		CARDS: 'Karty',
		NO_CARDS: 'Žádné karty k zobrazení',
		NEW_CARD: 'Nová karta',
	},
	CardItem: {
		EMAIL: 'Email',
		PHONE_ABBR: 'Tel',
		ADDRESS: 'Adresa',
		CARD_NUMBER: 'Číslo karty',
		CARD_NUMBER_INTERNAL: 'Interní číslo',
		BALANCE: 'Použitelný zůstatek',
		CZK: CURRENCIES.CZK,
		EDIT: 'Upravit',
		UNBLOCK: 'Odblokovat',
		DELETE: 'Smazat',
		BLOCK: 'Zablokovat',
		NO_CARDS: 'Žádné karty k zobrazení',

		DESCRIPTION: 'Popis',
		VALIDITY_FROM: 'Platná od',
		VALIDITY_TO: 'Platná do',
	},
	Envelopes: {
		BALANCE: 'Stav účtu',
		CZK: CURRENCIES.CZK,
		NEW_ENVELOPE: 'Nové konto',
		RECHARGE: 'Nabít',
		ENVELOPE_NAME: 'Název konta',
		ENVELOPE_BALANCE: 'Zůstatek',
		ENVELOPE_VALIDITY: 'Platnost',
		AMOUNT: 'Částka (Kč)',
		MOVE_AMOUNT: 'Přesunout prostředky',
		DESCRIPTION: 'Popis',
		LIMITS: 'KONTO LZE VYUŽÍT PRO:',
		ADVANCE_SETTINGS: 'Pokročilé nastavení',
		ALL_FACILITIES: 'Všechna sportoviště',
		ALL_ACTIVITIES: 'Všechny aktivity',
		VALIDITY_FROM: 'Platnost od',
		VALIDITY_TO: 'Platnost do',
		ACC_TYPE: 'Typ účtu',
		CREATE: 'Vytvořit',
		SAVE: 'Uložit',
		CLOSE: 'Zavřít',
		ACCOUNT: 'Finanční konto',
		CARD: 'Karta',
		EDIT: 'Upravit',
		SEARCH_USER: 'Vyhledat uživatele',
		SEARCH: 'Hledat',
		TOP_UP: 'Dobít',
		DELETE: 'Vymazat',
		DELETE_ENVELOPE: 'Vymazat konto',
		YES: 'Ano',
		NO: 'Ne',
		SOURCE: 'Zdroj',
		DESTINATION: 'Cíl',
		CONFIRM: 'Potvrdit',
		CHOOSE_DESTINATION: 'Vyberte konto, na které se převede celý zůstatek',
		CHOOSE_ENVELOPES: 'Vyberte konta mezi kterými se mají prostředky provést.',
		DELETE_ENVELOPE_TEXT: 'Jste si jistý, že chcete smazat toto konto?',
		CANNOT_DELETE_TEXT: 'Konto nelze smazat kvůli zbývajícímu zůstatku.',
		SUCCESS_SAVE: 'Zeměny byly úspěšne uloženy.',
		SUCCESS_CREATE: 'Konto bylo úspěšne vytvořeno.',
		SUCCESS_DELETE: 'Konto bylo úspěšne smazáno.',
		SUCCESS_MOVE_AMOUNT_1: 'Z konta "',
		SUCCESS_MOVE_AMOUNT_2: '" bylo na konto "',
		SUCCESS_MOVE_AMOUNT_3: '" úspěšně převedeno ',
		FAIL_SAVE: 'Při vytváření konta došlo k chybě.',
		FAIL_DELETE: 'Při mazaní konta došlo k chybě.',
		FAIL_MOVE_AMOUNT: 'Při přesunu přostředků došlo k chybě.',
		DAYS_0: '{{count}} den',
		DAYS_1: '{{count}} dny',
		DAYS_2: '{{count}} dní',
		STORNO: 'Storno',
		ENVELOPE_DESCRIPTION: 'Popis',
		ER_SAME_SOURCE_DEST: 'Zvolili jste stejné zdrojové a cílové konto',
		ER_MORE_AMOUNT_THEN_BALANCE: 'Nedostatek prostředků',
		ER_NEGATIVE_AMOUNT: 'Částka musí být větší než nula',
		NOT_UNIQUE: `Konto '{{name}}' již existuje.`,
		ACCOUNT_REMAINS_DAY: 'Konto je možné používat už jen {{days}} dní.',
		OWN_ENVELOPE: 'Vlastní konto',
		FOREIGN_ENVELOPE: 'Cizí konto',
		PAYMENT_ENVELOPE: 'Peněžní konto',
		PASS_ENVELOPE: 'Permanentkové konto',
	},
	Transactions: {
		TRANSACTION_HISTORY: 'Historie transakcí',
		TOTAL_INCOME: 'Vklady za období',
		TOTAL_OUTCOME: 'Výdaje za období',
		DATE: 'Datum',
		ACTIVITY: 'Aktivita',
		PLACE: 'Místo',
		CARD: 'Karta',
		AMOUNT: 'Částka',
		CZK: CURRENCIES.CZK,
		NO_DATA: 'Žádné transakce',
		FROM: 'Od',
		TO: 'Do',
		ALL: 'Vše',
		FILTER_ACTION: 'Filtrovat',
		FILTER: 'Filtry',
		FACILITY: 'Zařízení',
		ACCOUNT: 'Konto',
	},
	Trend: {
		TREND: 'Vývoj',
		BALANCE: 'Stav účtu',
		NO_DATA:
			'Graf nelze zobrazit, pokud u Vašeho účtu neexistují žádné transakce.',
	},
	Login: {
		BAD_CREDENTIALS: 'Špatné údaje',
		LOGIN_MESSAGE: 'Přihlašte se do svého SAREZA účtu',
		FIRST_TIME: 'Jste tady poprvé?',
		REGISTER: 'Zaregistrujte se.',
		REGISTER_ACTION: 'Registrovat',
		BIRTHDATE: 'Vaše datum narození',
		USERNAME: 'Užívatelské jméno',
		PASSWORD: 'Heslo',
		PASSWORD_AGAIN: 'Heslo znova',
		LOGIN: 'Přihlásit',
		FORGOTTEN_PASSWORD: 'Zapomněl jsem heslo.',
		FORGOTTEN_PASSWORD_TITLE: 'Obnova zapomenutého hesla',
		EMAIL: 'Váš e-mail',
		SEND: 'Odeslat',
		BACK: 'Zpět',
		DONE: 'Hotovo',
		ERROR: 'Vyskytla se chyba',
		REGISTRATION_CONFIRMED_ERROR: 'Ověření se nepodařilo.',
		REGISTRATION_SUCCESSFULL:
			'Registrace proběhla v pořádku. Pro dokončení klikněte na odkaz v e-mailu.',
		REGISTRATION_CONFIRMED: 'Účet byl úspěšně ověřen',
		REGISTRATION_TITLE: 'Registrace',
		ALREADY_REGISTERED: 'Už jste registrovaní?',
		'system.error.valueIsAlreadyUse': 'se již používá.',
		LOGIN_ACTION: 'Přihlašte se.',
		AGREEMENT: 'Souhlasím s EULA.',
		REQUIRED: 'Povinné pole',
		EMAIL_WRONG: 'Chybný formát e-mailu',
		PASSWORD_MIN_LENGTH: 'Heslo musí obsahovat nejméně 8 znaků',
		PASSWORDS_NO_MATCH: 'Zadaná hesla se neshodují',
	},
	PasswordStrength: {
		PASSWORDS_STRENGTH: 'Síla hesla',
		PASSWORD_MIN_LENGTH: 'Heslo je příliš krátké',
		PASSWORD_GOOD_LENGTH: 'Heslo je dostatečně dlouhé',
		PASSWORD_EXCELLENT_LENGTH: 'Heslo je ideálně dlouhé',
	},
	Facilities: {
		FACILITIES: 'Areály',
		BY_ACTIVITY: 'Podle aktivity',
		BY_PLACE: 'Podle místa',
		ALL: 'Vše',
		EDIT: 'Upravit',
		DELETE: 'Smazat',
		NEW: 'Přidat',
		NEW_FACILITY: 'Nový areál',
		EDIT_FACILITY: 'Editace areálu',
		ACTIVITIES_0: '{{count}} aktivita',
		ACTIVITIES_1: '{{count}} aktivity',
		ACTIVITIES_2: '{{count}} aktivit',
		NAME: 'Areál',
		DESCRIPTION: 'Popis',
		NAME_ACTIVITIES: 'Aktivity',
		VALIDITY: 'Platnost',
		CONFIRM_DELETE_FACILITY_TEXT: 'Opravdu smazat areál',
		FACILITY_EDIT: 'Editace areálu',
		FACILITY_NAME: 'Název',
		VALIDITY_FROM: 'Platnost od',
		VALIDITY_TO: 'Platnost do',
		ACTIVITIES_TITLE: 'Aktivity',
		SEND: 'Uložit',
		EDIT_FAIL: 'Chyba při ukládání areálu',
		NOT_FOUND: 'Žádný výsledek',
		NOT_FOUND_DESCRIPTION:
			'Omlouváme se, ale neexistuje žádný vyhovující výsledek.',
		LOADING: 'Už jen chvilku...',
	},
	FacilityDetail: {
		TYPE: ' wtf',
	},
	Admin: {
		ADMINISTRATION: 'Administrace',
		USERS: 'Uživatelé',
		ACCOUNTS: 'Účty',
		CARDS: 'Karty',
		AREALS: 'Areály',
		PAYMENT_TYPES: 'Typ platby',
		GROUP_OF_ACTIVITIES: 'Skupina aktivit',
	},
	Footer: {
		LINKS: 'Odkazy',
		CONTACT: 'Kontaktní údaje',
		GDPR: 'GDPR povolení',
		TERMS_AND_CONDITIONS: 'Obchodní podmínky',
		REPORT_PROBLEMS: 'Nahlásit chybu',
	},
};
