import { format } from 'date-fns';
import { each, keys, get, isNumber, isString } from 'lodash-es';

import {
	Balance,
	BalanceResponse,
} from 'modules/transactions/_transactionsTypes';

import { DIRECTION_DESC } from 'utils/constants';
import { fetch } from 'utils/utils';

export const defaultSortBy = 'transactionDate';
const defaultSort = { property: defaultSortBy, direction: DIRECTION_DESC };

export const mapFilters = (filters: any) => {
	const output: any[] = [];
	each(keys(filters), filter => {
		const date = get(filters, filter);

		switch (filter) {
			case 'from':
				if (date) {
					output.push({
						property: 'transactionDate',
						value: `>=${format(new Date(date), 'yyyy-MM-dd HH:mm:ss')}`,
					});
				}
				break;
			case 'to':
				if (date) {
					output.push({
						property: 'transactionDate',
						value: `<=${format(new Date(date), 'yyyy-MM-dd HH:mm:ss')}`,
					});
				}
				break;
			case 'facility':
				if (isNumber(date)) {
					output.push({
						property: 'facility.id',
						value: `=${date}`,
					});
				}
				break;
			case 'activity':
				if (isNumber(date)) {
					output.push({
						property: 'items.activity.id',
						value: `=${date}`,
					});
				}
				break;
			case 'account':
				if (isNumber(date)) {
					output.push({
						property: 'account.id',
						value: `=${date}`,
					});
				}
				break;

			default:
				break;
		}
	});
	return output;
};

export const mapSorting = (
	sort: string,
	direction: string = DIRECTION_DESC,
) => {
	if (!sort || !isString(sort)) {
		return [defaultSort];
	} else {
		const sorts = [{ property: sort, direction }];
		return sorts;
	}
};

export const getTransactions = async (
	filters: any = [],
	start = 0,
	limit = 10,
	sorts = mapSorting(null as any),
): Promise<any> => {
	try {
		const response = await fetch(`/sarezaWeb/api/transaction/all`, {
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/json' }),
			body: JSON.stringify({
				start,
				limit,
				sorts,
				filters,
			}),
		});

		return response;
	} catch (err) {
		return false;
	}
};

export async function getTotalTransactionBalance(
	dateFrom = '',
	dateTo = '',
): Promise<Balance | undefined> {
	try {
		const response = (await fetch(`/sarezaWeb/api/transaction/inoutbalance`, {
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/json' }),
			body: JSON.stringify({
				dateFrom: dateFrom,
				dateTo: dateTo,
			}),
		})) as BalanceResponse;

		return response.data;
	} catch (err) {
		return undefined;
	}
}
