import { combineReducers } from 'redux';
import { reducer as FormReducer } from 'redux-form';

import appReducer from './appReducer';

export interface TRootState {
	form: any;
	app: any;
}

export default combineReducers<TRootState>({
	form: FormReducer,
	app: appReducer,
});
