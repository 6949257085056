import React from 'react';
import { Field, GenericField } from 'redux-form';
import { Dropdown, Form } from 'semantic-ui-react';
import {
	compose,
	withStateHandlers,
	lifecycle,
	defaultProps,
	mapProps,
	withHandlers,
} from 'recompose';
import { identity, get } from 'lodash-es';

import { formWrapper } from 'utils/utils';
import Request from 'utils/request';

import * as T from './_selectTypes';

const View: React.FC<any> = ({
	errorText,
	options,
	handleChange,
	setOptions,
	...rest
}: any) => (
	<Dropdown
		{...rest}
		onChange={handleChange}
		error={!!errorText}
		search
		selection
		options={options}
		icon={null}
	/>
);

const EnhancedSelect = compose<any, any>(
	formWrapper,
	defaultProps({
		params: { limit: 20, start: 0 },
		labelFunction: (obj: any) => get(obj, 'text'),
		valueFunction: (obj: any) => get(obj, 'id'),
		mapItems: identity,
		options: [],
	}),
	withStateHandlers<any, any, any>((p: any) => ({ options: p.options }), {
		setOptions: props => (options: any) => options,
	}),
	lifecycle<any, any>({
		async componentDidMount() {
			const {
				url,
				setOptions,
				params,
				labelFunction,
				valueFunction,
				mapItems,
			} = this.props;
			if (url) {
				const request = await Request().post(url).send(params);
				const response = get(request, 'body');
				const rows = get(response, 'data.rows', []);
				const keyedRows = rows.map(mapItems).map((row: any) => ({
					key: row.id,
					value: valueFunction(row),
					text: labelFunction(row),
				}));
				setOptions({ options: keyedRows, serverOptions: rows.map(mapItems) });
			}
		},
	}),
	withHandlers<any, any>({
		handleChange: (props: any) => (e: any, v: any) => {
			const { serverOptions, valueFunction } = props;
			if (serverOptions) {
				const value = serverOptions.find(
					(vv: any) => valueFunction(vv) === v.value,
				);
				props.onChange(value);
			} else {
				props.onChange(v.value);
			}
		},
	}),
	mapProps(
		({
			params,
			labelFunction,
			valueFunction,
			serverOptions,
			value,
			mapItems,
			...rest
		}: any) => {
			return { value: isNaN(value) ? valueFunction(value) : value, ...rest };
		},
	),
)(View);

export const Select = (props: any) => {
	const { meta } = props;
	return (
		<Form.Field error={meta.touched && !!meta.error}>
			{!props.hideLabel && props.placeholder && (
				<label>{props.placeholder}</label>
			)}
			<Form.Group inline style={{ paddingLeft: 0 }}>
				<EnhancedSelect {...props} />
			</Form.Group>
			{meta.touched && <span style={{ color: '#9f3a38' }}>{meta.error}</span>}
		</Form.Field>
	);
};

export const FormSelect = Field as new () => GenericField<T.FormSelect>;
