import { fetch } from 'utils/utils';

import * as T from './_profileTypes';

export const changePassword = async (
	data: any,
): Promise<T.Password | boolean> => {
	try {
		const request = fetch(`/sarezaWeb/api/user/changePassword`, {
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/json' }),
			body: JSON.stringify(data),
		});

		const response = await request;

		return response;
	} catch (err) {
		return false;
	}
};

export const getProfile = async (): Promise<T.Password | boolean> => {
	try {
		const request = fetch(`/sarezaWeb/api/user/detail`, {
			method: 'GET',
			headers: new Headers({ 'Content-Type': 'application/json' }),
		});

		const response = await request;

		return response;
	} catch (err) {
		return false;
	}
};

export const selfUpdate = async (
	data: T.User,
): Promise<T.Password | boolean> => {
	try {
		const request = fetch(`/sarezaWeb/api/user/update`, {
			method: 'PUT',
			headers: new Headers({ 'Content-Type': 'application/json' }),
			body: JSON.stringify(data),
		});

		const response = await request;

		return response;
	} catch (err) {
		return false;
	}
};
