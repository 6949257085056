import React from 'react';
// import MediaQuery from 'react-responsive';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

// import * as c from 'utils/constants';
import { Divider, Grid, Header, Segment, Item } from 'semantic-ui-react';
import { times } from 'lodash-es';
import MediaQuery from 'react-responsive';

import * as c from 'utils/constants';

import * as T from './_newsTypes';

const enhance = compose(withTranslation('News'));

const View: React.FC<T.New> = () => {
	const screen = 8;
	const tavlet = 16;
	const mobile = 16;

	return (
		<Segment vertical>
			<Grid celled={false}>
				<Grid.Column widescreen={16}>
					<Header as="h1" style={{ display: 'flex', alignItems: 'center' }}>
						NEWS PLACHOLDER
					</Header>
					<MediaQuery maxWidth={c.BREAKPOINTS.tabletMax}>
						<Divider hidden />
					</MediaQuery>
				</Grid.Column>

				{times(10, index => (
					<Grid.Column
						key={index}
						computer={screen}
						mobile={mobile}
						tablet={tavlet}
					>
						<Item.Group>
							<Item>
								<Item.Image
									size="small"
									src="https://www.sareza.cz/wp-content/uploads/2017/08/Sareza_20170615_RS_4268-320x200.jpg"
								/>

								<Item.Content>
									<Item.Header as="a">KOUPALIŠTĚ OTEVŘENA</Item.Header>
									<Item.Meta>Description</Item.Meta>
									<Item.Description>
										Zahajujeme letní sezónu a otevíráme koupaliště: Vodní areál
										Jih, Vodní svět!!!, Krytý bazén Ostrava-Poruba, Letní
										koupaliště Ostrava-Poruba
									</Item.Description>
									<Item.Extra>
										Platí pro areály: Krytý bazén Ostrava-Poruba, Letní
										koupaliště Ostrava-Poruba, Vodní areál Jih, Vodní svět!!!
										(Čapkárna)
									</Item.Extra>
								</Item.Content>
							</Item>
						</Item.Group>
					</Grid.Column>
				))}
			</Grid>
		</Segment>
	);
};

export const News = enhance(View as any);
