import React from 'react';
import { Container, Header, Icon, Segment } from 'semantic-ui-react';

const View = () => (
	<Segment
		textAlign="center"
		style={{
			minHeight: '100%',
			padding: '1em 0em',
			display: 'flex',
			alignItems: 'center',
		}}
		vertical
	>
		<Container text>
			<Header as="h2" icon>
				<Icon name="settings" />
				SAREZA
				<Header.Subheader>
					Developerská verze informačního systému pro společnost Sportovní a
					rekreační zařízení města Ostravy{' '}
				</Header.Subheader>
			</Header>
		</Container>
	</Segment>
);

export const Homepage = View;
