import React from 'react';
import { get, keys, includes, without } from 'lodash-es';
import { reduxForm, getFormSyncErrors } from 'redux-form';
import { withTranslation } from 'react-i18next';
import {
	Header,
	Grid,
	Tab,
	Form,
	Button,
	Divider,
	Menu,
	Icon,
	Message,
	Transition,
} from 'semantic-ui-react';
import {
	compose,
	withState,
	lifecycle,
	withPropsOnChange,
	withHandlers,
	withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';

import CompanyForm from 'modules/profile/CompanyForm';

import { getProfile, selfUpdate } from './_profileActions';
import * as T from './_profileTypes';
import ProfileBasicForm from './ProfileBasicForm';
import ProfileAddressForm from './ProfileAddressForm';
import ProfileChangePassForm from './ProfileChangePassForm';

const enhance = compose(
	withTranslation('Profile'),
	withState<{}, boolean, string, string>('loader', 'setLoader', false),
	withState<{}, boolean, string, string>('sameBilling', 'setSame', false),
	withStateHandlers(
		() => ({
			message: {
				visible: false,
			},
		}),
		{
			setMessage: () => (message: any) => ({
				message,
			}),
		},
	),
	withHandlers({
		onSubmit: ({
			setMessage,
			setLoader,
			sameBilling,
			setSame,
			t,
		}: any) => async (values: T.User) => {
			setLoader(true);

			if (sameBilling) {
				setSame(false);
				values.addressBilling = values.address;
				setLoader(false);
			} else {
				const response = await selfUpdate(values);
				if (get(response, 'msgs')) {
					get(response, 'msgs').map((message: any) =>
						message.code === 'INFO'
							? setMessage({
									positive: true,
									visible: true,
									header: t(message.msg),
							  })
							: setMessage({
									negative: true,
									visible: true,
									header: t(message.msg),
							  }),
					);
				}
				setLoader(false);
			}
		},
	}),
	reduxForm<any>({ form: 'profileForm' }),
	connect(state => {
		const formSyncErrors = getFormSyncErrors('profileForm')(state);
		return {
			formSyncErrors,
			formHasErrors: keys(formSyncErrors).length,
		};
	}),
	lifecycle<any, any>({
		async componentDidMount() {
			const { initialize } = this.props;
			const response = await getProfile();
			initialize(get(response, 'data'));
		},
	}),
	withHandlers({
		renderTabButton: ({ t }: any) => (title: string, hasError: boolean) => (
			<Menu.Item key={title}>
				<span style={{ color: hasError ? '#db2828' : 'inherit' }}>
					{t(title)}
				</span>{' '}
				{hasError ? (
					<Icon color="red" name="warning circle" style={{ marginLeft: 10 }} />
				) : null}
			</Menu.Item>
		),
	}),
	withPropsOnChange(
		['formSyncErrors', 'submitFailed'],
		({
			renderTabButton,
			formSyncErrors,
			handleSubmit,
			submitFailed,
			setSame,
			setMessage,
			t,
		}: any) => {
			const tabsWithErros = keys(formSyncErrors);
			return {
				panes: [
					{
						menuItem: renderTabButton(
							'BASIC_INFO',
							submitFailed &&
								without(tabsWithErros, 'address', 'addressBilling').length,
						),
						pane: (
							<Tab.Pane
								key="basic"
								style={{ border: 0, padding: 0, background: 'transparent' }}
							>
								<ProfileBasicForm handleSubmit={handleSubmit} />
							</Tab.Pane>
						),
					},
					{
						menuItem: renderTabButton('COMPANY', submitFailed),
						pane: (
							<Tab.Pane
								key="company"
								style={{ border: 0, padding: 0, background: 'transparent' }}
							>
								<CompanyForm handleSubmit={handleSubmit} section="company" />
							</Tab.Pane>
						),
					},
					{
						menuItem: renderTabButton(
							'ADDRESS',
							submitFailed && includes(tabsWithErros, 'address'),
						),
						pane: (
							<Tab.Pane
								key="address"
								style={{ border: 0, padding: 0, background: 'transparent' }}
							>
								<ProfileAddressForm
									handleSubmit={handleSubmit}
									section="address"
								/>
							</Tab.Pane>
						),
					},
					{
						menuItem: renderTabButton(
							'DELIVERY_ADDRESS',
							submitFailed && includes(tabsWithErros, 'addressBilling'),
						),
						pane: (
							<Tab.Pane
								key="addressBilling"
								style={{ border: 0, padding: 0, background: 'transparent' }}
							>
								<Button
									color="teal"
									content={t('SAME_ADDRESS')}
									style={{ marginBottom: '1em' }}
									onClick={() => setSame(true)}
								/>
								<ProfileAddressForm
									handleSubmit={handleSubmit}
									section="addressBilling"
								/>
							</Tab.Pane>
						),
					},
					{
						menuItem: renderTabButton(
							'CHANGE_PASSWORD',
							submitFailed && includes(tabsWithErros, 'changePassword'),
						),
						pane: (
							<Tab.Pane
								key="changePassword"
								style={{ border: 0, padding: 0, background: 'transparent' }}
							>
								<ProfileChangePassForm setMessage={setMessage} t={t} />
							</Tab.Pane>
						),
					},
				],
			};
		},
	),
);

const View: React.FC<T.ProfileForm> = ({
	loader,
	handleSubmit,
	message,
	setMessage,
	panes,
	t,
	submitFailed,
	formHasErrors,
}) => (
	<>
		<Grid celled={false} padded="horizontally">
			<Divider hidden />
			<Grid.Row>
				<Grid.Column width={12}>
					<Form loading={loader} onSubmit={handleSubmit}>
						<Transition
							visible={message.visible}
							animation="fade down"
							duration={500}
						>
							<Message
								onDismiss={() => setMessage({ ...message, visible: false })}
								{...message}
							/>
						</Transition>
						<Header as="h1">{t('USER_PROFILE')}</Header>
						<Tab
							panes={panes}
							menu={{ secondary: true, pointing: true, color: 'teal' }}
							renderActiveOnly={false}
						/>
						{submitFailed && formHasErrors ? (
							<Message color="red">Ve formuláři jsou chyby</Message>
						) : null}
					</Form>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	</>
);

export const Profile = enhance(View as any);
