import { get } from 'lodash-es';
import React from 'react';
import { Label } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { compose, withPropsOnChange } from 'recompose';

import { validators } from 'utils/validate';

import * as T from './_passwordStrengthTypes';

const enhance = compose<T.PasswordStrength, any>(
	withTranslation('PasswordStrength'),
	withPropsOnChange(['password'], ({ password, t }: any) => {
		let error;
		if (validators.passwordMinLength(password)) {
			error = {
				message: t(validators.passwordMinLength(password)),
				color: 'red',
			};
		}
		if (validators.passwordGoodLength(password)) {
			error = {
				message: t(validators.passwordGoodLength(password)),
				color: 'yellow',
			};
		}
		if (validators.passwordExcellentLength(password)) {
			error = {
				message: t(validators.passwordExcellentLength(password)),
				color: 'green',
			};
		}
		return { error };
	}),
);

const View: React.FC<T.PasswordStrength> = ({ t, password, error }) => (
	<div>
		{password?.length && (
			<div>
				{t('PASSWORDS_STRENGTH')}:{' '}
				<Label color={get(error, 'color')}>{get(error, 'message')}</Label>
			</div>
		)}
	</div>
);

export default enhance(View);
