import React from 'react';
import MediaQuery from 'react-responsive';
import { withTranslation } from 'react-i18next';
import { reduxForm, getFormValues } from 'redux-form';
import { get, identity, flow, uniqBy } from 'lodash-es';
import {
	Header,
	Grid,
	Segment,
	Divider,
	Item,
	Form,
	Message,
	Icon,
	Label,
} from 'semantic-ui-react';
import {
	compose,
	lifecycle,
	withState,
	onlyUpdateForKeys,
	withPropsOnChange,
	withProps,
} from 'recompose';
import { connect } from 'react-redux';

import { FormSelect, Select } from 'components/form/select/Select';

import {
	activities,
	ALL_PLACES_KEY,
} from 'modules/facilities/_facilitiesConstants';
import { ActivityIcon } from 'modules/admin/facilities/ActivityIcon';

import * as c from 'utils/constants';
import { Dictionary } from 'utils/utils';

import {
	getAllFacilities,
	filterByActivity,
	groupActivitiesByType,
	filterByLocation,
} from './_facilitiesActions';
import * as T from './_facilitiesTypes';

const ActivitiesInFacility: React.FC<{
	groupedActivities: Dictionary<T.ActivityItem[]>;
}> = ({ groupedActivities }) => {
	const result = [];

	for (const key in groupedActivities) {
		if (!key) {
			continue;
		}

		const filteredActivities = groupedActivities[key];

		const activitiesToString = filteredActivities
			.filter(activity => activity?.name)
			.map(activity => activity?.name)
			.join(', ');

		if (!activitiesToString) {
			continue;
		}

		result.push(
			<Item.Description key={key}>
				{activitiesToString}
				<br />
				<Item.Extra>
					<Label basic color="blue" pointing="above" size="mini">
						<ActivityIcon id={key} />
					</Label>
				</Item.Extra>
			</Item.Description>,
		);
	}

	return <>{result}</>;
};

const enhance = compose(
	withTranslation('Facilities'),
	withState<any, boolean, string, string>('loading', 'setLoading', false),
	withState<any, T.Area[], string, string>('facilities', 'setFacilities', []),
	lifecycle<any, any>({
		async componentDidMount() {
			this.props.setLoading(true);

			const facilities = await getAllFacilities();

			this.props.setFacilities(facilities);

			this.props.setLoading(false);
		},
	}),
	withProps(({ t }: any) => ({
		enchancedFilterByActivity: filterByActivity(activities[0].value),
	})),
	withProps(({ t }: any) => ({
		initialValues: { activity: activities[0].value, place: ALL_PLACES_KEY },
	})),
	reduxForm({
		form: 'facilityForm',
	}),
	onlyUpdateForKeys(['facilities', 'loading']),
	connect(state => ({
		activity: get(getFormValues('facilityForm')(state), 'activity'),
		place: get(getFormValues('facilityForm')(state), 'place'),
	})),
	withPropsOnChange(
		['activity', 'facilities', 'place'],
		({ activity, facilities, place, enchancedFilterByActivity }: any) => {
			const filteredFacilities = flow<
				T.Area[],
				T.Area[],
				T.Area[],
				T.AreaWithGroupedActivities[]
			>(
				enchancedFilterByActivity(activity),
				filterByLocation(place),
				groupActivitiesByType,
			)(facilities);

			return {
				filteredFacilities,
			};
		},
	),
	withPropsOnChange(
		['facilities'],
		({ facilities, t }: { facilities: T.Area[]; t: any }) => {
			const locations = facilities.map(facility => ({
				value: facility.location,
				text: facility.location,
			}));

			return {
				places: uniqBy(
					[{ value: ALL_PLACES_KEY, text: t('ALL') }].concat(locations),
					'value',
				),
			};
		},
	),
);

const View: React.FC<T.Facilities> = ({
	t,
	filteredFacilities,
	places,
	loading,
}) => {
	if (loading) {
		return (
			<Segment vertical>
				<Message icon>
					<Icon name="circle notched" loading />
					<Message.Content>
						<Message.Header>{t('LOADING')}</Message.Header>
					</Message.Content>
				</Message>
			</Segment>
		);
	}

	return (
		<Segment vertical>
			<Grid celled={false} padded="horizontally">
				<MediaQuery minWidth={c.BREAKPOINTS.desktopMin}>
					<Divider hidden />
				</MediaQuery>

				<Grid.Row>
					<Grid.Column tablet={16} computer={6}>
						<Header as="h1" style={{ display: 'flex', alignItems: 'center' }}>
							{t('FACILITIES')}
						</Header>
						<MediaQuery maxWidth={c.BREAKPOINTS.tabletMax}>
							<Divider hidden />
						</MediaQuery>
					</Grid.Column>
					<Grid.Column computer={6}>
						<Form size="small">
							<Form.Group inline>
								{activities.length ? (
									<>
										<label>{t('BY_ACTIVITY')}</label>
										<FormSelect
											name="activity"
											component={Select}
											options={activities}
											valueFunction={identity}
											fluid
											style={{ width: 170 }}
										/>
									</>
								) : null}
								{places.length ? (
									<>
										<label>{t('BY_PLACE')}</label>
										<FormSelect
											name="place"
											component={Select}
											options={places}
											valueFunction={identity}
											fluid
											style={{ width: 170 }}
										/>
									</>
								) : null}
							</Form.Group>
						</Form>
					</Grid.Column>
				</Grid.Row>

				<Grid.Row>
					<Grid.Column mobile={16} widescreen={12}>
						<Item.Group divided relaxed>
							{filteredFacilities.map(facility => (
								<Item key={facility.name}>
									<Item.Image
										target="_blank"
										rel="noopener noreferrer"
										as="a"
										size="medium"
										src={facility['image-url']}
										href={facility.url}
									/>
									<Item.Content>
										<Item.Header
											target="_blank"
											rel="noopener noreferrer"
											as="a"
											href={facility.url}
										>
											{facility.name}
										</Item.Header>
										<Item.Meta>{facility.location}</Item.Meta>
										<ActivitiesInFacility
											groupedActivities={facility.groupedActivities}
										/>
									</Item.Content>
								</Item>
							))}
						</Item.Group>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			{!filteredFacilities.length && !loading ? (
				<Message
					warning
					icon="box"
					header={t('NOT_FOUND')}
					content={t('NOT_FOUND_DESCRIPTION')}
				/>
			) : null}
		</Segment>
	);
};

export const Facilities = enhance(View as any);
