import React from 'react';
import { isArray, find } from 'lodash-es';
import MediaQuery from 'react-responsive';
import { withTranslation } from 'react-i18next';
import { compose, lifecycle, withStateHandlers, withHandlers } from 'recompose';
import {
	Header,
	Grid,
	Icon,
	Button,
	Divider,
	Segment,
	Transition,
	Message,
} from 'semantic-ui-react';

import * as c from 'utils/constants';

import { EnvelopesTable } from './EnvelopesTable';
import { EnvelopeForm } from './EnvelopeForm';
import { EnvelopeConfirm } from './EnvelopeConfirm';
import { EnvelopeMoveAmount } from './EnvelopeMoveAmount';
import * as T from './_envelopesTypes';
import { envelopeModals } from './_envelopesEnums';
import {
	getEnvelopes,
	sumTranactions,
	saveEnvelope,
	deleteEnvelope,
	postMoveAmount,
} from './_envelopesActions';

const enhance = compose<T.Envelopes, {}>(
	withTranslation('Envelopes'),
	withStateHandlers<T.EnvelopesState, any, {}>(
		() => ({
			[`opened_${envelopeModals.FORM}`]: false,
			[`opened_${envelopeModals.CONFIRM}`]: false,
			[`opened_${envelopeModals.MOVE_AMOUNT}`]: false,
			id: 0,
			message: {
				visible: false,
			},
			envelopes: [],
			balanceAmount: 0,
			isFetchingEnvelopes: true,
		}),
		{
			open: () => (modal: T.EnvelopeModalTypes, id: number) => ({
				[`opened_${modal}`]: true,
				id,
			}),
			close: () => (modal: T.EnvelopeModalTypes) => ({
				[`opened_${modal}`]: false,
				// id: 0, // smazano kvuli ticketu 'Účet - nejde zároveň vyprázdnit a odebrat' - nenasel jsem duvod proc by se zde melo nastavovat id na 0
			}),
			setMessage: () => (message: any) => ({
				message,
			}),
			setEnvelopes: () => (envelopes: any) => ({
				envelopes,
			}),
			setBalanceAmount: () => (balanceAmount: any) => ({
				balanceAmount,
			}),
			toggleFetchingEnvelopes: () => (isFetchingEnvelopes: any) => ({
				isFetchingEnvelopes,
			}),
		},
	),
	withHandlers<T.Envelopes, T.EnvelopeHandlers>({
		saveAndClose: ({ setEnvelopes, setMessage, close, t }: any) => async (
			values: T.Envelope,
		) => {
			if (await saveEnvelope(values)) {
				setMessage({
					positive: true,
					visible: true,
					header: values.id ? t('SUCCESS_SAVE') : t('SUCCESS_CREATE'),
				});
			} else {
				setMessage({
					negative: true,
					visible: true,
					header: t('FAIL_SAVE'),
				});
			}

			const response: Array<T.Envelope> | boolean = await getEnvelopes();

			if (isArray(response)) {
				setEnvelopes(response);
			}
			close('form');
		},
		moveAndClose: ({ envelopes, setEnvelopes, setMessage, close, t }) => async (
			values: any,
		) => {
			if (await postMoveAmount(values)) {
				const from = envelopes.find(e => e.id === values.source)?.name;
				const to = envelopes.find(e => e.id === values.destination)?.name;
				setMessage({
					positive: true,
					visible: true,
					header:
						t('SUCCESS_MOVE_AMOUNT_1') +
						from +
						t('SUCCESS_MOVE_AMOUNT_2') +
						to +
						t('SUCCESS_MOVE_AMOUNT_3') +
						values.amount +
						' ' +
						t('CZK') +
						'.',
				});
			} else {
				setMessage({
					negative: true,
					visible: true,
					header: t('FAIL_MOVE_AMOUNT'),
				});
			}

			const response: Array<T.Envelope> | boolean = await getEnvelopes();

			if (isArray(response)) {
				setEnvelopes(response);
			}
			close('moveAmount');
		},
		deleteAndClose: ({ setEnvelopes, setMessage, close, t }) => async (
			id: number,
		) => {
			if (await deleteEnvelope(id)) {
				setMessage({
					positive: true,
					visible: true,
					header: t('SUCCESS_DELETE'),
				});
			} else {
				setMessage({
					negative: true,
					visible: true,
					header: t('FAIL_DELETE'),
				});
			}

			const response: Array<T.Envelope> | boolean = await getEnvelopes();

			if (isArray(response)) {
				setEnvelopes(response);
			}
			close('confirm');
		},
	}),
	lifecycle<Partial<T.Envelopes>, {}>({
		async componentDidMount() {
			const { setEnvelopes, setBalanceAmount, toggleFetchingEnvelopes } = this
				.props as any;

			const envelopes: Array<T.Envelope> | boolean = await getEnvelopes();

			if (isArray(envelopes)) {
				setEnvelopes(envelopes);

				const balanceAmount = sumTranactions(envelopes);
				setBalanceAmount(balanceAmount);
			}

			toggleFetchingEnvelopes(false);
		},
	}),
);

const View: React.FC<T.Envelopes> = ({
	message,
	setMessage,
	envelopes,
	balanceAmount,
	opened_form,
	opened_confirm,
	opened_moveAmount,
	id,
	open,
	close,
	saveAndClose,
	deleteAndClose,
	moveAndClose,
	isFetchingEnvelopes,
	t,
}) => (
	<>
		{opened_form && (
			<EnvelopeForm
				opened_form={opened_form}
				open={open}
				close={close}
				saveAndClose={saveAndClose}
				id={id}
			/>
		)}
		{opened_confirm && (
			<EnvelopeConfirm
				opened_confirm={opened_confirm}
				open={open}
				close={close}
				deleteAndClose={deleteAndClose}
				id={id}
				envelope={find(envelopes, { id: parseInt(String(id), 10) })}
			/>
		)}
		{opened_moveAmount && (
			<EnvelopeMoveAmount
				opened_moveAmount={opened_moveAmount}
				open={open}
				close={close}
				moveAndClose={moveAndClose}
				id={id}
				envelope={find(envelopes, { id: parseInt(String(id), 10) })}
			/>
		)}
		<Segment vertical loading={isFetchingEnvelopes}>
			<Grid celled={false} padded="horizontally">
				<MediaQuery minWidth={c.BREAKPOINTS.desktopMin}>
					<Divider hidden />
				</MediaQuery>

				<Transition
					visible={message.visible}
					animation="fade down"
					duration={500}
				>
					<Message
						onDismiss={() => setMessage({ ...message, visible: false })}
						{...message}
					/>
				</Transition>

				<Grid.Row>
					<Grid.Column computer={8} mobile={16}>
						<Header as="h1" floated="left">
							{t('BALANCE')}:
						</Header>
						<Header as="h1" floated="left" color="green">
							{balanceAmount} {t('CZK')}
						</Header>
					</Grid.Column>

					<Grid.Column computer={8} mobile={16}>
						<MediaQuery maxWidth={c.BREAKPOINTS.mobileMax}>
							{matches => (
								<div
									style={
										matches
											? { display: 'flex', flexDirection: 'column' }
											: undefined
									}
									className={'envelopeBttns'}
								>
									<Button
										size="large"
										color="teal"
										basic
										onClick={() => {
											console.info('## IMPLEMENT');
										}}
										className={'button--card'}
									>
										<Icon name="dollar sign" />
										{t('RECHARGE')}
									</Button>
									<Button
										size="large"
										color="teal"
										basic
										onClick={() => open('form', '')}
										style={matches ? { marginBottom: 15 } : null}
										className={'button--card'}
									>
										<Icon name="add" />
										{t('NEW_ENVELOPE')}
									</Button>
								</div>
							)}
						</MediaQuery>
					</Grid.Column>
				</Grid.Row>

				<Grid.Row>
					<Grid.Column mobile={16} widescreen={16}>
						<EnvelopesTable envelopes={envelopes} open={open} close={close} />
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
	</>
);

export const Envelopes = enhance(View);
