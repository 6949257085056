import { USER_STORE } from 'utils/constants';
import { fetch } from 'utils/utils';
import * as storage from 'utils/storage';

// const loginUser = async (username: string, password: string): Promise<any> => {
//   try {
//     await fetch(`/sarezaWeb/authenticate?username=${username}&password=${password}`, {
//       method: 'POST',
//       headers: new Headers({ 'Content-Type': 'application/json' })
//     });

//     return true;
//   } catch (err) {
//     return false;
//   }
// };

const loginUser = async (
	username: string,
	password: string,
	recaptcha: string | null = null,
): Promise<any> => {
	try {
		const values: any = {
			username,
			password,
		};
		if (recaptcha) {
			values.recaptcha = recaptcha;
		}
		await fetch(`/sarezaWeb/sign/rest`, {
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/json' }),
			body: JSON.stringify(values),
		});

		return true;
	} catch (err) {
		return false;
	}
};

const getUserInfo = () => async (dispatch: any) => {
	try {
		const user = await fetch(`/sarezaWeb/api/sec/account`, {
			method: 'GET',
			headers: new Headers({ 'Content-Type': 'application/json' }),
		});

		dispatch({
			payload: user,
			type: USER_STORE,
		});
		return true;
	} catch (err) {
		return false;
	}
};
// export const loginUserWithFacebook = (accessToken: string) => async (dispatch: any) => {
//   try {
//     const request = await Request()
//       .post('/sarezaWeb/sign/facebook')
//       .query({ accessToken });

//     const response = get(request, 'body');
//     dispatch({
//       payload: get(response, 'msgs', []),
//       type: c.SET_MESSAGES
//     });

//     return response;
//   } catch (error) {
//     return error;
//   }
// };

const logoutUser = async () => {
	await fetch(`/sarezaWeb/api/sec/logout`, {
		method: 'POST',
		headers: new Headers({ 'Content-Type': 'application/json' }),
	});

	storage.remove('token');
};

export { loginUser, logoutUser, getUserInfo };
