import { fetch } from 'utils/utils';
import { formHasError } from 'utils/form';
// import * as T from './_envelopesTypes';

export const changeForgottenPassword = async (data: object): Promise<any> => {
	try {
		const response = await fetch(`/sarezaWeb/api/ns/forgottenPassword`, {
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/json' }),
			body: JSON.stringify({
				...data,
			}),
		});

		const hasErrors = formHasError(response);

		if (hasErrors) {
			return false;
		} else {
			return true;
		}
	} catch (err) {
		return false;
	}
};

export const forgottenPassword = async (email: string) => {
	try {
		const response = await fetch(
			`/sarezaWeb/api/ns/forgottenPassword?email=${email}`,
			{
				headers: new Headers({ 'Content-Type': 'application/json' }),
				method: 'GET',
			},
		);

		return response.data;
	} catch (err) {
		return false;
	}
};
