import { get } from 'lodash-es';

import * as T from './_profileTypes';

export const PasswordFormValidator = (values: T.Password) => {
	const errors: any = {};
	if (get(values, 'password1') !== get(values, 'password2')) {
		errors.password2 = 'Hesla se neshodují';
	}
	return errors;
};
