const Defaults = require('superagent-defaults');
// import { getSession } from './storage';

export default function authRequest(withToken = false) {
	// const token = getSession('token');

	const superagent = new Defaults();

	// if (withToken && token) {
	//   superagent.set('Authorization', `Bearer ${token}`);
	// }

	return superagent;
}
