import React from 'react';
import { get } from 'lodash-es';
import { reduxForm } from 'redux-form';
import classnames from 'classnames';
import MediaQuery from 'react-responsive';
import { withTranslation } from 'react-i18next';
import { compose, withState } from 'recompose';
import { Grid, Segment, Icon, Button, Form } from 'semantic-ui-react';

import { notEmpty } from 'utils/validate';
import * as c from 'utils/constants';
import { hasAuthority } from 'utils/utils';

import * as T from './_cardsTypes';

const enhance = compose<any, any>(
	withTranslation('CardItem'),
	reduxForm<any>({ form: 'envelopeName' }),
	withState<{}, boolean, string, string>('editActive', 'setEditActive', false),
);

const handleSubmit = (setEditActive: (x: boolean) => void) => {
	console.log('submited');
	setEditActive(false);
};

const View: React.FC<T.CardItem> = ({
	card,
	isMobileMax,
	blockCard,
	unblockCard,
	editActive,
	setEditActive,
	t,
}) => (
	<Segment
		padded
		key={get(card, 'id')}
		secondary
		color={
			get(card, 'blockByUser', false) || get(card, 'blockByAdmin', false)
				? 'red'
				: 'green'
		}
		className={classnames('segment--card', { 'segment--mobile': isMobileMax })}
	>
		<h3>
			<Icon name="credit card" /> Karta {get(card, 'id', '')} -{' '}
			{get(card, 'blockByAdmin', false)
				? 'zablokovana administrátorem'
				: get(card, 'blockByUser', false)
				? 'zablokovana uživatelem'
				: get(card, 'account.name', '')}
		</h3>
		<Grid>
			<Grid.Row>
				<Grid.Column mobile={16} largeScreen={6} widescreen={6}>
					<div className="card-content">
						<p>
							{t('DESCRIPTION')}:{' '}
							{editActive ? (
								<>
									<Form onSubmit={() => handleSubmit(setEditActive)}>
										<Form.Input
											name="name"
											placeholder={t('ENVELOPE_NAME')}
											validate={notEmpty}
											defaultValue={get(card, 'description', '')}
										/>
									</Form>{' '}
									<Icon
										onClick={() => handleSubmit(setEditActive)}
										name="checkmark"
										color="green"
										style={{ cursor: 'pointer' }}
									/>
								</>
							) : (
								<strong>
									{get(card, 'description', '')}{' '}
									<Icon onClick={() => setEditActive(true)} name="pencil" />
								</strong>
							)}
						</p>
						<p>
							{t('VALIDITY_FROM')}:{' '}
							<strong>
								{new Date(get(card, 'validityFrom', '-')).toLocaleDateString(
									'cs-CZ',
								)}
							</strong>
						</p>
						<p>
							{t('VALIDITY_TO')}:{' '}
							<strong>
								{new Date(get(card, 'validityTo', '-')).toLocaleDateString(
									'cs-CZ',
								)}
							</strong>
						</p>
					</div>
				</Grid.Column>
				<Grid.Column mobile={16} largeScreen={6} widescreen={6}>
					<div className="card-content">
						<p>
							{t('CARD_NUMBER')}: <strong>{get(card, 'numberExt', '-')}</strong>
						</p>
						{hasAuthority(c.ADMIN) ? (
							<p>
								{t('CARD_NUMBER_INTERNAL')}:{' '}
								<strong>{get(card, 'numberInt', '-')}</strong>
							</p>
						) : (
							<p>&nbsp;</p>
						)}
						<p>&nbsp;</p>
						{/*
              <p style={{ color: '#21ba45' }}>
              {t('BALANCE')}: <strong>xxx {t('CZK')}</strong>
              </p>
              */}
					</div>
				</Grid.Column>
				<Grid.Column mobile={16} largeScreen={4} widescreen={4}>
					<MediaQuery maxWidth={c.BREAKPOINTS.desktopMax}>
						{isDesktopMax => (
							<div
								style={
									isMobileMax
										? {
												display: 'flex',
												flexDirection: 'column',
												marginTop: 15,
										  }
										: isDesktopMax
										? { marginTop: 15 }
										: undefined
								}
							>
								{!get(card, 'blockByAdmin', false) ? (
									<Button
										style={{ display: 'block' }}
										basic
										color={get(card, 'blockByUser', false) ? 'green' : 'orange'}
										size="medium"
										className="button--card"
										onClick={() =>
											get(card, 'blockByUser', false)
												? unblockCard(card, false)
												: blockCard(card, false)
										}
									>
										{get(card, 'blockByUser', false) ? (
											<>
												<Icon name="check circle" /> {t('UNBLOCK')}
											</>
										) : (
											<>
												<Icon name="minus circle" /> {t('BLOCK')}
											</>
										)}
									</Button>
								) : null}
							</div>
						)}
					</MediaQuery>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	</Segment>
);

export const CardItem = enhance(View);
