import { isEmpty, once } from 'lodash-es';

import { fetch } from 'utils/utils';
import * as c from 'utils/constants/';

import * as storage from './storage';

export const setBreadcrumbs = (breadcrumbs: any) => ({
	payload: { breadcrumbs },
	type: c.BREADCRUMBS,
});

const renewAction = async () => {
	const token = storage.get('token');
	if (isEmpty(token) || token === 'null') {
		await fetch(`/sarezaWeb/logout`, {
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/json' }),
		});

		return;
		// if (window.location.pathname !== '/login') {
		//   window.location.pathname = '/login';

		//   return;
		// }
	}

	try {
		const response = await fetch('/sarezaWeb/api/sec/account', {
			method: 'GET',
		});

		storage.put('token', JSON.stringify(response));
	} catch (e) {
		await fetch(`/sarezaWeb/logout`, {
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/json' }),
		});

		storage.remove('token');
		window.location.pathname = '/login';
	}
};

export const renewToken = once(() => {
	renewAction();
	setInterval(renewAction, 60 * 1000); // 60 s interval refresh
});
