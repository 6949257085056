import React from 'react';
import { get } from 'lodash-es';
import { withTranslation } from 'react-i18next';
import { Button, Modal, Icon } from 'semantic-ui-react';
import { compose } from 'recompose';

import * as T from './_envelopesTypes';

const enhance = compose<any, any>(withTranslation('Envelopes'));

const View: React.FC<T.EnvelopeForm> = ({
	opened_confirm,
	close,
	id,
	deleteAndClose,
	envelope,
	t,
	open,
}) => (
	<Modal
		size="tiny"
		open={opened_confirm}
		onClose={() => close('confirm')}
		closeOnEscape={false}
	>
		<Modal.Header>{t('DELETE_ENVELOPE')}</Modal.Header>
		<Modal.Content>
			<div>
				{get(envelope, 'balanceAmount') > 0 ? (
					<>
						{t('CANNOT_DELETE_TEXT')}
						<div style={{ marginTop: 10 }}>
							<Button
								onClick={() => open('moveAmount', String(id))}
								primary
								size="small"
							>
								<Icon name="resize horizontal" />
								{t('MOVE_AMOUNT')}
							</Button>
						</div>
					</>
				) : (
					t('DELETE_ENVELOPE_TEXT')
				)}
			</div>
		</Modal.Content>
		<Modal.Actions>
			<Button
				color="orange"
				onClick={() => close('confirm')}
				content={t('NO')}
			/>
			<Button
				positive
				onClick={() => deleteAndClose(id)}
				icon="checkmark"
				labelPosition="right"
				content={t('YES')}
				disabled={get(envelope, 'balanceAmount') > 0}
			/>
		</Modal.Actions>
	</Modal>
);

export const EnvelopeConfirm = enhance(View);
