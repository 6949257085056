import React from 'react';
import { withTranslation } from 'react-i18next';
import { isArray, compact, identity, get, filter } from 'lodash-es';
import { Button, Modal, Form, Grid, Divider, Segment } from 'semantic-ui-react';
import {
	compose,
	withHandlers,
	withState,
	lifecycle,
	withPropsOnChange,
} from 'recompose';
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import { FormInput, Input } from 'components/form/input/Input';
import { FormSelect, Select } from 'components/form/select/Select';

import { notEmpty } from 'utils/validate';

import { getEnvelopes } from './_envelopesActions';
import * as T from './_envelopesTypes';

const adapter = (array: Array<any>) =>
	compact(
		array.map((envelope: any, id: any) => ({
			value: envelope.id,
			text: `${envelope.name} - ${envelope.balanceAmount}Kč`,
			balance: envelope.balanceAmount,
			key: id,
		})),
	);

const validate = (values: any, { t, options }: any) => {
	const errors: any = {};

	const amount = parseInt(get(values, 'amount', 0), 10);
	const source = get(values, 'source');
	const destination = get(values, 'destination');

	if (source) {
		if (source === destination) {
			errors.source = t('ER_SAME_SOURCE_DEST');
			errors.destination = ' ';
		}

		if (
			options.length &&
			amount >
				(source
					? filter(options, (o: any) => o.value === get(values, 'source'))[0]
							.balance
					: 0)
		) {
			errors.amount = t('ER_MORE_AMOUNT_THEN_BALANCE');
		}
	}

	if (amount < 1) {
		errors.amount = t('ER_NEGATIVE_AMOUNT');
	}

	return errors;
};

const enhance = compose<any, any>(
	withTranslation('Envelopes'),
	withState<{}, Array<T.Envelope>, string, string>('options', 'setOptions', []),
	withState<{}, boolean, string, string>('loader', 'setLoader', true),
	lifecycle<any, any>({
		async componentDidMount() {
			const options: Array<T.Envelope> | boolean = await getEnvelopes();

			if (isArray(options)) {
				this.props.setOptions(adapter(options));
			}

			this.props.setLoader(false);
		},
	}),
	withHandlers({
		onSubmit: ({ moveAndClose }: any) => async (values: any) => {
			values.amount = parseInt(values.amount, 10);

			moveAndClose(values);
		},
	}),
	withPropsOnChange(['id'], ({ id, envelope }: any) => ({
		initialValues: id
			? {
					source: parseInt(String(id), 10),
					amount: get(envelope, 'balanceAmount'),
			  }
			: {},
	})),
	reduxForm({ form: 'envelopeMoveAmountForm', validate }),
	connect(
		state => ({
			values: getFormValues('envelopeMoveAmountForm')(state),
		}),
		{},
	),
);

const View: React.FC<any> = ({
	opened_moveAmount,
	close,
	id,
	envelope,
	moveAndClose,
	handleSubmit,
	loader,
	options,
	values,
	t,
}) => (
	<Modal
		size="large"
		open={opened_moveAmount}
		onClose={() => close('confirm')}
		closeOnEscape={false}
	>
		<Modal.Header>{t('MOVE_AMOUNT')}</Modal.Header>
		<Modal.Content>
			<Segment vertical loading={loader} style={{ padding: 0 }}>
				<p>{id ? t('CHOOSE_DESTINATION') : t('CHOOSE_ENVELOPES')}</p>
				{!loader && (
					<Modal.Description>
						<Form loading={loader} onSubmit={handleSubmit}>
							<Grid columns="equal" stackable padded="vertically">
								<Grid.Row>
									<Grid.Column>
										<FormSelect
											name="source"
											placeholder={t('SOURCE')}
											component={Select}
											validate={notEmpty}
											options={options}
											valueFunction={identity}
											disabled={id ? true : false}
											fluid
										/>
									</Grid.Column>
									<Grid.Column>
										<FormSelect
											name="destination"
											placeholder={t('DESTINATION')}
											component={Select}
											validate={notEmpty}
											options={
												get(values, 'source')
													? filter(
															options,
															(o: any) => o.value !== get(values, 'source'),
													  )
													: options
											}
											valueFunction={identity}
											fluid
										/>
									</Grid.Column>
								</Grid.Row>
							</Grid>

							<Divider />

							<FormInput
								name="amount"
								label={t('AMOUNT')}
								icon="money bill alternate"
								placeholder=""
								width={3}
								component={Input}
								validate={notEmpty}
								type="number"
								min="1"
								max={
									get(values, 'source')
										? filter(
												options,
												(o: any) => o.value === get(values, 'source'),
										  )[0].balance
										: 0
								}
								step="1"
							/>
						</Form>
					</Modal.Description>
				)}
			</Segment>
		</Modal.Content>
		<Modal.Actions>
			<Button
				color="orange"
				onClick={() => {
					close('moveAmount');
					close('confirm');
				}}
				content={t('STORNO')}
			/>
			<Button
				positive
				onClick={handleSubmit}
				icon="checkmark"
				labelPosition="right"
				content={t('CONFIRM')}
			/>
		</Modal.Actions>
	</Modal>
);

export const EnvelopeMoveAmount = enhance(View);
