import React from 'react';
import { get, isEqual } from 'lodash-es';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { withTranslation } from 'react-i18next';
import { reduxForm, getFormValues } from 'redux-form';
import {
	Header,
	Grid,
	Divider,
	Statistic,
	Segment,
	Form,
} from 'semantic-ui-react';
import { format, subMonths } from 'date-fns';
import {
	compose,
	lifecycle,
	withState,
	withPropsOnChange,
	withHandlers,
	withStateHandlers,
} from 'recompose';

import { FormDate, Date as DateComponent } from 'components/form/input/Date';

import * as c from 'utils/constants';

import { TransactionsTable } from './TransactionsTable';
import {
	getTransactions,
	mapFilters,
	mapSorting,
	defaultSortBy,
	getTotalTransactionBalance,
} from './_transactionsActions';

const transactionsPerPage = 10;

const enhance = compose(
	withTranslation('Transactions'),
	withStateHandlers(
		() => ({
			transactions: [],
			transactionsCount: null,
			totalPages: 0,
			activePage: 1,
			income: '-',
			outcome: '-',
			sort: defaultSortBy,
			direction: c.DIRECTION_DESC,
		}),
		{
			setTransactions: () => (transactions, count) => ({
				transactions,
				transactionsCount: count,
				totalPages:
					count % transactionsPerPage > 0
						? Math.floor(count / transactionsPerPage) + 1
						: Math.floor(count / transactionsPerPage),
			}),
			setTotals: () => (income, outcome) => ({ income, outcome }),
			handlePaginationChange: () => (e: any, pages: any) => ({
				activePage: pages.activePage,
			}),
			setActivePage: () => page => ({ activePage: page }),
			setSorting: ({ sort, direction }) => newSort => {
				let newDirection;
				if (sort === newSort) {
					newDirection =
						direction === c.DIRECTION_DESC ? c.DIRECTION_ASC : c.DIRECTION_DESC;
				} else {
					newDirection = c.DIRECTION_DESC;
				}
				return { sort: newSort, direction: newDirection };
			},
		},
	),
	withState<{}, boolean, string, string>(
		'isFetchingTransactions',
		'toggleFetchingTransactions',
		true,
	),
	withPropsOnChange([], () => {
		console.log({
			from: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
			to: format(new Date(), 'yyyy-MM-dd'),
		});

		return {
			initialValues: {
				from: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
				to: format(new Date(), 'yyyy-MM-dd'),
			},
		};
	}),
	reduxForm({ form: 'transactionsForm' }),
	connect((state: any) => ({
		filters: getFormValues('transactionsForm')(state),
	})),
	withHandlers({
		fetchTransactions: ({
			toggleFetchingTransactions,
			setTransactions,
			filters,
			sort,
		}: any) => async (start = 0, sortBy: string = sort, direction: string) => {
			toggleFetchingTransactions(true);
			const response = await getTransactions(
				mapFilters(filters),
				start,
				transactionsPerPage,
				mapSorting(sortBy, direction),
			);

			const transactions = get(response, 'data.rows', []);
			const transactionsCount = get(response, 'data.count');

			setTransactions(transactions, transactionsCount);
			toggleFetchingTransactions();
		},
	}),
	withHandlers({
		updateTotalIncomeAndOutcome: ({ filters, setTotals }: any) => () => {
			getTotalTransactionBalance(filters.from, filters.to).then(
				response => response && setTotals(response.in, response.out),
			);
		},
	}),
	lifecycle<any, any>({
		componentDidMount() {
			this.props.fetchTransactions();
			this.props.updateTotalIncomeAndOutcome();
		},
		componentDidUpdate({
			filters: oldFilters,
			activePage: oldActivePage,
			sort: oldSort,
			direction: oldDirection,
		}: any) {
			const {
				filters: newFilters,
				activePage: newActivePage,
				sort: newSort,
				direction: newDirection,
				setActivePage,
			} = this.props;
			if (
				!isEqual(oldFilters, newFilters) ||
				oldActivePage !== newActivePage ||
				oldSort !== newSort ||
				oldDirection !== newDirection
			) {
				this.props.fetchTransactions(
					(newActivePage - 1) * transactionsPerPage,
					newSort,
					newDirection,
				);
				this.props.updateTotalIncomeAndOutcome();
			}
			if (
				!isEqual(oldFilters.from, newFilters.from) ||
				!isEqual(oldFilters.to, newFilters.to)
			) {
				setActivePage(1);
			}
		},
	}),
);

const View: React.FC<any> = ({
	transactions,
	income,
	outcome,
	isFetchingTransactions,
	activePage,
	totalPages,
	handlePaginationChange,
	setSorting,
	sort,
	direction,
	t,
}) => (
	<>
		<Segment vertical loading={isFetchingTransactions}>
			<Grid celled={false} padded="horizontally">
				<MediaQuery minWidth={c.BREAKPOINTS.desktopMin}>
					<Divider hidden />
				</MediaQuery>

				<Grid.Row>
					<Grid.Column tablet={16} computer={6}>
						<Header as="h1">{t('TRANSACTION_HISTORY')}</Header>
						<MediaQuery maxWidth={c.BREAKPOINTS.tabletMax}>
							<Divider hidden />
						</MediaQuery>
					</Grid.Column>
					<Grid.Column computer={6}>
						<Form size="small">
							<Form.Group widths="equal">
								{[
									{ label: t('FROM'), name: 'from' },
									{ label: t('TO'), name: 'to' },
								].map((field: any) => (
									<Form.Field
										inline
										style={{ display: 'flex', alignItems: 'center' }}
										key={field.name}
									>
										<label>{field.label}</label>
										<FormDate
											name={field.name}
											placeholder=""
											component={DateComponent}
											style={{ width: 170 }}
										/>
									</Form.Field>
								))}
							</Form.Group>
						</Form>
					</Grid.Column>
				</Grid.Row>

				<MediaQuery maxWidth={c.BREAKPOINTS.tabletMax}>
					{isTabletMax => (
						<Grid.Row>
							{[
								{ color: 'green', value: income, label: t('TOTAL_INCOME') },
								{ color: 'orange', value: outcome, label: t('TOTAL_OUTCOME') },
							].map((stat: any) => (
								<Grid.Column
									mobile={16}
									tablet={8}
									computer={6}
									widescreen={3}
									key={stat.color}
								>
									<Statistic
										color={stat.color}
										style={{
											...(isTabletMax
												? { width: '100%', marginBottom: 10 }
												: {}),
										}}
										horizontal
										size="tiny"
									>
										<Statistic.Value>{stat.value || '-'},-</Statistic.Value>
										<Statistic.Label>{stat.label}</Statistic.Label>
									</Statistic>
								</Grid.Column>
							))}
						</Grid.Row>
					)}
				</MediaQuery>

				<Grid.Row>
					<Grid.Column computer={16} tablet={16}>
						<TransactionsTable
							transactions={transactions}
							totalPages={totalPages}
							transactionsPerPage={transactionsPerPage}
							activePage={activePage}
							onPageChange={handlePaginationChange}
							setSorting={setSorting}
							sort={sort}
							direction={direction}
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
	</>
);

export const Transactions = enhance(View);
