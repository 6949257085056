import React from 'react';
import { reduxForm } from 'redux-form';
import MediaQuery from 'react-responsive';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Grid, Message, Form, Button } from 'semantic-ui-react';
import { compose, withHandlers, withState, lifecycle } from 'recompose';

import { FormInput, Input } from 'components/form/input/Input';

import style from 'utils/style';
import { notEmpty } from 'utils/validate';
import * as c from 'utils/constants/';

import * as T from './_forgottenPasswordTypes';
import { forgottenPassword } from './_forgottenPasswordActions';

const enhance = compose(
	withRouter,
	withTranslation('Login'),
	withState('loader', 'setLoader', false),
	withHandlers<any, any>({
		onSubmit: (props: T.OnSubmit) => async (values: any) => {
			const { email } = values;
			props.setLoader(true);
			await forgottenPassword(email);
			props.setLoader(false);
		},
		onClose: (props: T.OnClose) => (value: any) => {
			props.history.push('/login');
		},
	}),
	lifecycle({
		componentDidUpdate(prevProps: any) {
			const { userLoaded, history } = this.props;
			if (userLoaded) {
				history.push('/');
			}
		},
	}),
	reduxForm({ form: 'forgottenPasswordForm' }),
);

const View: React.FC<T.ForgottenPassword> = ({
	handleSubmit,
	onClose,
	loader,
	msgs,
	t,
}) => (
	<MediaQuery maxWidth={c.BREAKPOINTS.mobileMax}>
		{isMobileMax => (
			<Grid
				textAlign="center"
				verticalAlign="middle"
				style={{
					height: '100%',
					...{
						marginLeft: isMobileMax ? 0 : null,
						marginRight: isMobileMax ? 0 : null,
					},
				}}
			>
				<Grid.Column
					mobile={15}
					tablet={7}
					computer={5}
					largeScreen={4}
					widescreen={3}
				>
					<Message attached>
						<Message.Header style={style('colors.text.primary')}>
							{t('FORGOTTEN_PASSWORD_TITLE')}
						</Message.Header>
					</Message>
					<Form
						size="small"
						className="attached fluid segment"
						loading={loader}
						onSubmit={handleSubmit}
					>
						<FormInput
							name="email"
							placeholder={t('EMAIL')}
							icon="user"
							component={Input}
							validate={notEmpty}
							errorTextPosition="bottom"
						/>
						{/* <Messages msgs={msgs} style={{ marginBottom: 10 }} /> */}
						<Button content={t('SEND')} color="teal" type="submit" />
						<Button content={t('BACK')} onClick={onClose} />
					</Form>
				</Grid.Column>
			</Grid>
		)}
	</MediaQuery>
);

export const ForgottenPassword = enhance(View as any);
