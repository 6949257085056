import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

const FacilityDetail: React.FC<RouteComponentProps<{ facility: string }>> = ({
	match: {
		params: { facility },
	},
}) => {
	return <div>{facility}</div>;
};

export { FacilityDetail };
