import React from 'react';
import { Image } from 'semantic-ui-react';

import SwimmingBlue from 'assets/img/swimming-blue.png';
import HearRed from 'assets/img/heart-red.png';
import DotGreen from 'assets/img/dot-green.png';

export const ActivityIcon: React.FC<{ id: string }> = ({ id = '' }) => {
	switch (id) {
		case 's-vodou':
			return (
				<>
					<Image src={SwimmingBlue} avatar /> S vodou
				</>
			);
		case 'relax':
			return (
				<>
					<Image src={HearRed} avatar /> Relax
				</>
			);
		default:
			return (
				<>
					<Image src={DotGreen} avatar /> Bez vody
				</>
			);
	}
};
