import React from 'react';
import MediaQuery from 'react-responsive';
import { withTranslation } from 'react-i18next';
import { Header, Table, Icon } from 'semantic-ui-react';
import { compose } from 'recompose';

import * as c from 'utils/constants/index';

import * as T from './_envelopesTypes';
import { EnvelopesTableItem } from './EnvelopesTableItem';

const ehnance = compose<any, any>(withTranslation('Envelopes'));

const View: React.FC<T.Envelopes> = ({ envelopes, open, t }) => (
	<Table
		striped
		celled={false}
		padded
		relaxed="very"
		fixed={false}
		attached
		color="teal"
	>
		<MediaQuery minWidth={c.BREAKPOINTS.tabletMin}>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell singleLine>
						<Header as="h4" inverted>
							<Icon name="mail" />
							<Header.Content>{t('ENVELOPE_NAME')}</Header.Content>
						</Header>
					</Table.HeaderCell>
					<Table.HeaderCell>{t('ENVELOPE_DESCRIPTION')}</Table.HeaderCell>
					<Table.HeaderCell sorted="ascending">
						{t('ENVELOPE_BALANCE')}
					</Table.HeaderCell>
					<Table.HeaderCell>{t('ENVELOPE_VALIDITY')}</Table.HeaderCell>
					<Table.HeaderCell />
				</Table.Row>
			</Table.Header>
		</MediaQuery>
		<MediaQuery minWidth={c.BREAKPOINTS.tabletMin}>
			{isTabletMin => (
				<Table.Body>
					{envelopes.map((e: T.Envelope, index: number) => (
						<EnvelopesTableItem key={index} envelope={e} open={open} />
					))}
				</Table.Body>
			)}
		</MediaQuery>
	</Table>
);

export const EnvelopesTable = ehnance(View);
