import { sumBy, get } from 'lodash-es';

import { User } from 'modules/profile/_profileTypes';
import { Card } from 'modules/cards/_cardsTypes';

import { fetch } from 'utils/utils';
import { formHasError } from 'utils/form';

import { Envelope } from './_envelopesTypes';
import * as T from './_envelopesTypes';

export const getEnvelopes = async (): Promise<Array<T.Envelope> | boolean> => {
	try {
		const response = await fetch(`/sarezaWeb/api/envelope/all`, {
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/json' }),
			body: JSON.stringify({
				start: 0,
				limit: 999,
			}),
		});

		return response.data.rows;
	} catch (err) {
		return false;
	}
};

export const getEnvelope = async (
	id: number,
): Promise<T.Envelope | boolean> => {
	try {
		const response = await fetch(`/sarezaWeb/api/envelope/${id}`, {
			method: 'GET',
			headers: new Headers({ 'Content-Type': 'application/json' }),
		});

		return response.data;
	} catch (err) {
		return false;
	}
};

export const getAllAccounts = async (): Promise<T.Account[] | boolean> => {
	try {
		const response: T.GenericApiResponse<T.Account> = await fetch(
			`/sarezaWeb/api/user/allAccounts`,
			{
				method: 'GET',
				headers: new Headers({ 'Content-Type': 'application/json' }),
			},
		);

		// TODO: IMPLEMENT GET ALL ACCOUNTS || CHECK FUNCTIONALITY
		return response.data;
	} catch (error) {
		return false;
	}
};

export const saveEnvelope = async (
	data: any,
): Promise<T.Envelope | boolean> => {
	try {
		const request = data.id
			? fetch(`/sarezaWeb/api/envelope/${data.id}`, {
					method: 'PUT',
					headers: new Headers({ 'Content-Type': 'application/json' }),
					body: JSON.stringify(data),
			  })
			: fetch(`/sarezaWeb/api/envelope/create`, {
					method: 'POST',
					headers: new Headers({ 'Content-Type': 'application/json' }),
					body: JSON.stringify(data),
			  });

		const response = await request;

		const hasErrors = formHasError(response);

		if (hasErrors) {
			return false;
		} else {
			return response;
		}
	} catch (err) {
		return false;
	}
};

export const deleteEnvelope = async (id: number) => {
	try {
		const response = await fetch(`/sarezaWeb/api/envelope/${id}`, {
			method: 'DELETE',
			headers: new Headers({ 'Content-Type': 'application/json' }),
		});

		const hasErrors = formHasError(response);

		if (hasErrors) {
			return false;
		} else {
			return true;
		}
	} catch (err) {
		return false;
	}
};

export const getActivities = async (): Promise<Array<T.Activity> | boolean> => {
	try {
		const response = await fetch(`/sarezaWeb/api/activity/all`, {
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/json' }),
			body: JSON.stringify({
				start: 0,
				limit: 999,
				sorts: [
					{
						property: 'name',
						direction: 'asc',
					},
				],
			}),
		});

		return response.data.rows;
	} catch (err) {
		return false;
	}
};

export const getFacilities = async (): Promise<Array<T.Facility> | boolean> => {
	try {
		const response = await fetch(`/sarezaWeb/api/facility/all`, {
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/json' }),
			body: JSON.stringify({
				start: 0,
				limit: 999,
				sorts: [
					{
						property: 'name',
						direction: 'asc',
					},
				],
			}),
		});

		return response.data.rows;
	} catch (err) {
		return false;
	}
};

export const sumTranactions = (envelopes: any) =>
	sumBy(envelopes, 'balanceAmount');

export const postMoveAmount = async (values: any): Promise<any> => {
	try {
		const response = await fetch(`/sarezaWeb/api/envelope/moveamount`, {
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/json' }),
			body: JSON.stringify(values),
		});

		const hasErrors = formHasError(response);

		if (hasErrors) {
			return false;
		} else {
			return response;
		}
	} catch (err) {
		return false;
	}
};

export const isPernamentType = (envelope?: Envelope | T.EnvelopeFormValues) =>
	Boolean(envelope?.ticket);

export const getCards = async (): Promise<Array<T.Card> | boolean> => {
	try {
		const response: T.GenericApiResponse<Card> = await fetch(
			`/sarezaWeb/api/card/all`,
			{
				method: 'POST',
				headers: new Headers({ 'Content-Type': 'application/json' }),
				body: JSON.stringify({
					start: 0,
					limit: 999,
				}),
			},
		);

		return get(response, 'data.rows');
	} catch (error) {
		return false;
	}
};

export const getUsers = async (
	lastName = '*',
): Promise<Array<User> | boolean> => {
	try {
		const response: T.GenericApiResponse<User> = await fetch(
			`/sarezaWeb/api/admin/user/all`,
			{
				method: 'POST',
				headers: new Headers({ 'Content-Type': 'application/json' }),
				body: JSON.stringify({
					start: 0,
					limit: 999,
					filters: [{ property: 'lastName', value: lastName }],
				}),
			},
		);

		return get(response, 'data.rows');
	} catch (error) {
		return false;
	}
};
