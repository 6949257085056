import React from 'react';
import * as ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';

import App from './App';
import registerServiceWorker from './registerServiceWorker';
import configureStore from './utils/store';

const store = configureStore();

const rootEl = document.getElementById('root') as HTMLElement;

ReactDOM.render(<App store={store} />, rootEl);

// if (module.hot) {
//   module.hot.accept('./App', () => {
//     const NextApp = require('./App').default;
//     ReactDOM.render(<NextApp />, rootEl);
//   });
// }

registerServiceWorker();
