import React from 'react';
import { get, isArray } from 'lodash-es';
import MediaQuery from 'react-responsive';
import { withTranslation } from 'react-i18next';
import { compose, lifecycle, withStateHandlers, withHandlers } from 'recompose';
import { Header, Grid, Divider, Segment, Button } from 'semantic-ui-react';

import { hasAuthority } from 'utils/utils';
import * as c from 'utils/constants';

import { putCard, getAll } from './_cardsActions';
import * as T from './_cardsTypes';
import { CardItem } from './CardItem';

const enhance = compose(
	withTranslation('Cards'),
	withStateHandlers(
		{ cards: [], isFetchingCards: true },
		{
			setCards: () => cards => ({ cards, isFetchingCards: false }),
			startFetch: state => () => ({ ...state, isFetchingCards: true }),
			endFetch: state => () => ({ ...state, isFetchingCards: false }),
		},
	),
	withHandlers({
		getAllCards: ({ setCards }: any) => async () => {
			const data = {
				data: {
					rows: [
						{
							id: 1,
							numberInt: '112233445566',
							numberExt: '010203040506',
							pin: '123456',
							blockByUser: false,
							blockByAdmin: false,
							validityFrom: '2018-09-01',
							validityTo: '2019-08-31',
							description: 'Testovaci karta!',
							account: {
								id: 3,
								name: 'InQool, a.s.',
								description: 'ccc',
							},
						},
						{
							id: 2,
							numberInt: '112233445566',
							numberExt: '010203040506',
							pin: '123456',
							blockByUser: true,
							blockByAdmin: false,
							validityFrom: '2018-09-01',
							validityTo: '2019-08-31',
							description: 'Testovaci karta!',
							account: {
								id: 3,
								name: 'InQool, a.s.',
								description: 'ccc',
							},
						},
						{
							id: 3,
							numberInt: '112233445566',
							numberExt: '010203040506',
							pin: '123456',
							blockByUser: false,
							blockByAdmin: true,
							validityFrom: '2018-09-01',
							validityTo: '2019-08-31',
							description: 'Testovaci karta!',
							account: {
								id: 3,
								name: 'InQool, a.s.',
								description: 'ccc',
							},
						},
					],
					count: 3,
				},
			};

			await getAll().then(
				response => setCards(get(data, 'data.rows', [])),
				() => setCards([]),
			);
			// await getAll().then(response => setCards(get(response, 'data.rows', [])), () => setCards([]));
		},
	}),
	lifecycle<any, any>({
		async componentDidMount() {
			this.props.getAllCards();
		},
	}),
	withHandlers({
		blockCard: ({ getAllCards, startFetch, endFetch }: any) => async (
			card: T.Card,
			byAdmin = false,
		) => {
			startFetch();
			byAdmin
				? await putCard({ ...card, blockByAdmin: true }).then(
						async () => getAllCards(),
						() => endFetch(),
				  )
				: await putCard({ ...card, blockByUser: true }).then(
						async () => getAllCards(),
						() => endFetch(),
				  );
		},
		unblockCard: ({ getAllCards, startFetch, endFetch }) => async (
			card: T.Card,
			byAdmin = false,
		) => {
			startFetch();
			byAdmin
				? await putCard({ ...card, blockByAdmin: false }).then(
						async () => getAllCards(),
						() => endFetch(),
				  )
				: await putCard({ ...card, blockByUser: false }).then(
						async () => getAllCards(),
						() => endFetch(),
				  );
		},
		deleteCard: ({ getAllCards, startFetch, endFetch }) => async (
			card: T.Card,
		) => {
			/*
      startFetch();
      await putCard({ ...card, blockByUser: false }).then(async () => getAllCards(), () => endFetch());
      */
		},
	}),
);

const View: React.FC<T.Cards> = ({
	cards,
	isFetchingCards,
	blockCard,
	unblockCard,
	deleteCard,
	t,
}) => {
	return (
		<MediaQuery maxWidth={c.BREAKPOINTS.mobileMax}>
			{isMobileMax => (
				<Segment vertical>
					<Grid celled={false} padded="horizontally" id="carsGrid">
						<MediaQuery minWidth={c.BREAKPOINTS.desktopMin}>
							<Divider hidden />
						</MediaQuery>

						<Grid.Row>
							<Grid.Column mobile={16} widescreen={12}>
								<Header as="h1" floated="left">
									{t('CARDS')}
								</Header>
								{hasAuthority(c.ADMIN) ? (
									<Button>{t('NEW_CARD')}</Button>
								) : null}
							</Grid.Column>
						</Grid.Row>

						<Grid.Row>
							<Grid.Column mobile={16} largeScreen={14} widescreen={12}>
								<Segment vertical loading={isFetchingCards}>
									{isArray(cards) && cards.length ? (
										cards.map((card: T.Card) => (
											<CardItem
												key={card.id}
												card={card}
												isMobileMax={isMobileMax}
												blockCard={blockCard}
												unblockCard={unblockCard}
											/>
										))
									) : (
										<div>{t('NO_CARDS')}</div>
									)}
								</Segment>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Segment>
			)}
		</MediaQuery>
	);
};

export const Cards = enhance(View as any);
