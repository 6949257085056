import React from 'react';
import { get, isArray, compact } from 'lodash-es';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router';
import MediaQuery from 'react-responsive';
import { withTranslation } from 'react-i18next';
import {
	Header,
	Grid,
	Segment,
	Divider,
	Form,
	Button,
	Message,
} from 'semantic-ui-react';
import {
	compose,
	lifecycle,
	withPropsOnChange,
	withState,
	withHandlers,
} from 'recompose';

import { FormDate, Date } from 'components/form/input/Date';
import { FormInput, Input } from 'components/form/input/Input';
import { FormTextArea, TextArea } from 'components/form/textArea/TextArea';
import { FormCheckbox, Checkbox } from 'components/form/checkbox/Checkbox';

import { getFacility } from 'modules/facilities/_facilitiesActions';
import { getActivities } from 'modules/envelopes/_envelopesActions';
import { Activity } from 'modules/envelopes/_envelopesTypes';
import { Facility } from 'modules/facilities/_facilitiesTypes';

import { notEmpty } from 'utils/validate';
import * as c from 'utils/constants';

import { updateFacility } from './_facilitiesActions';
import * as T from './_facilitiesTypes';

const adapter = (array: Array<any>) =>
	compact(
		array.map((_: any, id: any) => ({
			id,
		})),
	);

const enhance = compose(
	withTranslation('Facilities'),
	withRouter,
	withPropsOnChange(['match'], ({ match }: any) => ({
		id: get(match, 'params.id'),
	})),
	withState('loader', 'setLoader', false),
	withState<{}, string, string, string>(
		'formError',
		'setFormError',
		null as any,
	),
	withState<{}, Array<Facility>, string, string>(
		'facility',
		'fetchFacility',
		null as any,
	),
	withState<{}, Array<Activity>, string, string>(
		'activities',
		'fetchActivities',
		[],
	),
	withHandlers({
		onSubmit: ({ history, setFormError, setLoader, t }: any) => async (
			values: any,
		) => {
			setLoader(true);
			setFormError(null);
			const newValues = {
				...values,
				activities: adapter(values.activities || []),
			};
			const response = await updateFacility(values.id, newValues);
			if (response) {
				history.push('/admin?activeTab=facilities');
			} else {
				setFormError(t('EDIT_FAIL'));
				setLoader(false);
			}
		},
	}),
	reduxForm({ form: 'facilityForm' }),
	lifecycle<any, any>({
		async componentDidMount() {
			const { setLoader } = this.props;
			setLoader(true);
			const [facilityRequest, activities] = await Promise.all([
				getFacility(this.props.id),
				getActivities(),
			]);
			const facility = get(facilityRequest, 'data');
			if (facility) {
				this.props.fetchFacility(facility);
				this.props.initialize(facility);
			}
			if (isArray(activities)) {
				this.props.fetchActivities(activities);
			}
			setLoader(false);
		},
	}),
);

const View: React.FC<T.FaciltyForm> = ({
	t,
	facility,
	handleSubmit,
	activities,
	formError,
	loader,
}) => (
	<Form onSubmit={handleSubmit} loading={loader}>
		<Segment vertical>
			<Grid celled={false} padded="horizontally">
				<MediaQuery minWidth={c.BREAKPOINTS.desktopMin}>
					<Divider hidden />
				</MediaQuery>

				{facility ? (
					<>
						<Grid.Row>
							<Grid.Column tablet={16}>
								<Header as="h1">
									{t('FACILITY_EDIT')}: {get(facility, 'name')}
								</Header>
								<MediaQuery maxWidth={c.BREAKPOINTS.tabletMax}>
									<Divider hidden />
								</MediaQuery>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column tablet={16} computer={6} widescreen={4}>
								<FormInput
									name="name"
									label={t('FACILITY_NAME')}
									icon="mail outline"
									placeholder=""
									width={16}
									component={Input}
									validate={notEmpty}
								/>
							</Grid.Column>
							<Grid.Column mobile={16} />
							<Grid.Column mobile={16} computer={6} widescreen={4}>
								<FormDate
									name="validityFrom"
									width={16}
									label={t('VALIDITY_FROM')}
									placeholder=""
									component={Date}
								/>
								<FormDate
									name="validityTo"
									width={16}
									label={t('VALIDITY_TO')}
									placeholder=""
									component={Date}
								/>
								<FormTextArea
									name="description"
									label={t('DESCRIPTION')}
									placeholder=""
									width={16}
									component={TextArea}
								/>
							</Grid.Column>
							<Grid.Column mobile={16} />
						</Grid.Row>
						<Grid.Row>
							<Grid.Column mobile={16}>
								<Header as="h2" icon>
									{t('ACTIVITIES_TITLE')}
								</Header>
								<Grid celled={false} padded="horizontally">
									<Grid.Row>
										{activities.map((item: Activity, index: number) => (
											<Grid.Column key={index} largeScreen={4} widescreen={3}>
												<div style={{ marginBottom: 5, marginRight: 5 }}>
													<FormCheckbox
														name={`activities.${item.id}`}
														label={item.name}
														component={Checkbox}
													/>
												</div>
											</Grid.Column>
										))}
									</Grid.Row>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						{formError && (
							<Grid.Row>
								<Grid.Column mobile={16}>
									<Message negative>
										<p>{formError}</p>
									</Message>
								</Grid.Column>
							</Grid.Row>
						)}
						<Grid.Row>
							<Grid.Column mobile={16}>
								<Button content={t('SEND')} color="teal" type="submit" />
							</Grid.Column>
						</Grid.Row>
					</>
				) : null}
			</Grid>
		</Segment>
	</Form>
);

export const FacilityForm = enhance(View as any);
