import React from 'react';
import { get } from 'lodash-es';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { compose, withState, withHandlers } from 'recompose';
import { Header, Grid, Divider, Form, Button } from 'semantic-ui-react';

import Messages from 'components/form/messages/Messages';
import { FormInput, Input } from 'components/form/input/Input';

import { notEmpty } from 'utils/validate';

import * as T from './_profileTypes';
import { PasswordFormValidator } from './_profileValidations';
import { changePassword } from './_profileActions';

const enhance = compose(
	withTranslation('Profile'),
	withState<{}, boolean, string, string>('loader', 'setLoader', false),
	withState<{}, any, string, string>('msgs', 'setMessages', []),
	withHandlers({
		onSubmit: ({ setLoader, setMessages }: any) => async (
			values: T.Password,
		) => {
			setLoader(true);
			const response = await changePassword(values);
			setMessages(get(response, 'msgs', []));
			setLoader(false);
		},
	}),
	reduxForm<T.Password>({
		form: 'passwordForm',
		validate: PasswordFormValidator,
	}),
);

const View: React.FC<T.PasswordForm> = ({ loader, handleSubmit, msgs, t }) => (
	<Grid celled={false} padded="horizontally">
		<Divider hidden />
		<Grid.Row>
			<Grid.Column>
				<Header as="h1" floated="left">
					{t('CHANGE_PASSWORD')}
				</Header>
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column>
				<Form loading={loader} onSubmit={handleSubmit}>
					<Messages msgs={msgs as any} style={{ marginBottom: 10 }} />
					{[
						{
							name: 'oldPassword',
							label: t('OLD_PASSWORD'),
							validate: notEmpty,
						},
						{ name: 'password1', label: t('NEW_PASSWORD'), validate: notEmpty },
						{
							name: 'password2',
							label: t('NEW_PASSWORD_AGAIN'),
							validate: notEmpty,
						},
					].map(field => (
						<FormInput
							{...field}
							width={4}
							icon="privacy"
							placeholder=""
							key={field.name}
							component={Input}
						/>
					))}
					<div>
						<Button
							color="teal"
							onClick={handleSubmit}
							content={t('SAVE_PASSWORD')}
						/>
					</div>
				</Form>
			</Grid.Column>
		</Grid.Row>
	</Grid>
);

export const Password = enhance(View as any);
