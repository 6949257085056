import React from 'react';
import { Global, css } from '@emotion/core';

const GlobalStyles = () => (
	<Global
		styles={css`
			body {
				margin: 0;
				padding: 0;
				font-family: 'Roboto', sans-serif;
			}

			a {
				color: #003065;
			}

			#root {
				height: 100vh;
			}

			.ui.header.page-header {
				border-bottom: 2px solid black;
			}

			.ui.inverted.menu {
				background: #003065;
			}

			.ui.vertical.menu {
				background: #60bbce;
				background-image: url('assets/img/sidebar-bg.png');
				background-repeat: no-repeat;
				background-position: bottom center;
			}

			.ui.dimmer {
				background-color: rgba(0, 0, 0, 0.5);
			}

			.sareza-logo {
				position: fixed;
				width: 100%;
				height: 100%;
				background-image: url('./assets/img/sareza-grey.svg');
				background-repeat: no-repeat;
				background-position: 130% 100%;
				background-size: 50% 50%;
				filter: blur(5px);
			}

			.sidebar-menu {
				text-align: left;
				z-index: 101;
				padding-top: 4.5rem;
			}
			.sidebar-menu .sidebar-menu__first {
				margin-top: 4.5rem;
			}

			.ui.table thead tr:first-child > th:first-child,
			.ui.table thead tr:first-child > th:last-child {
				border-radius: 0;
			}

			.ui.teal.table {
				border-top: 0;
				border: 0;
				box-shadow: 2px 2px 10px #ddd;
			}

			.ui.table.selectable tbody tr:hover td,
			.ui.table.selectable tbody tr:hover th {
				cursor: pointer;
			}

			.ui.table.teal thead th {
				background-color: #60bbce;
				color: #fff;
			}

			.ui.teal.button,
			.ui.teal.buttons .button {
				background-color: #60bbce;
			}

			.ui.modal > .header {
				background-color: #60bbce;
				color: white;
			}

			.ui.teal.button:hover,
			.ui.teal.buttons .button:hover {
				background-color: #509eaf;
			}

			.ui.segment.segment--card {
				border-radius: 0;
				border-left-style: solid;
				border-left-width: 12px;
			}
			.ui.segment.segment--card.secondary {
				color: rgba(0, 0, 0, 0.8);
			}
			.ui.segment.segment--card.red:not(.inverted),
			.ui.segment.segment--card.green:not(.inverted) {
				border-top: 1px solid rgba(34, 36, 38, 0.15) !important;
			}
			.ui.segment.segment--card.red:not(.inverted) {
				border-left-color: #db2828;
			}
			.ui.segment.segment--card.green:not(.inverted) {
				border-left-color: #21ba45;
			}
			.ui.segment.segment--card.segment--mobile {
				border-left-width: 6px;
			}

			.card-content {
				font-size: 1.1em;
			}
			.card-content p {
				margin: 0;
				font-size: 1em;
			}

			.envelopeBttns {
				text-align: right;
			}

			.ui.ui.button.basic.button--envelope,
			.ui.ui.button.basic.button--card {
				box-shadow: 0 0 0 transparent !important;
				-webkit-box-shadow: 0 0 0 transparent !important;
			}

			.ui.ui.button.basic.button--card {
				display: inline-block;
				padding: 0;
				margin: 0.5em 0 0 2em;
			}

			i.icon.table--filter {
				cursor: pointer;
				box-shadow: none;
				background: #fff;
				margin-left: 10px;
				opacity: 0.7;
				transition: opacity 0.1s linear;
			}
			i.icon.table--filter:hover {
				opacity: 1;
			}

			.ui.table thead th.table--filter-header {
				overflow: visible;
				vertical-align: top;
			}

			.ui[class*='middle aligned'].grid > .column:not(.row) {
				min-width: 356px;
			}

			.ui.search.dropdown > .text {
				max-width: 100%;
			}

			.ui.message {
				padding-right: 2em;
			}

			#carsGrid .segment--card .card-content form {
				display: inline-block;
			}

			#carsGrid .segment--card .card-content strong i {
				opacity: 0;
				cursor: pointer;
				transition: opacity 0.4s ease;
			}

			#carsGrid .segment--card:hover .card-content strong i {
				opacity: 0.5;
			}
			#carsGrid .segment--card:hover .card-content strong i:hover {
				opacity: 1;
			}

			.ui.modal.envelopeForm {
				width: 90vw;
			}

			.table--clickable {
				cursor: pointer !important;
			}

			.ui.modal > .header,
			.ui.table.teal thead th,
			.ui.teal.button,
			.ui.teal.buttons .button,
			.ui.blue.button,
			.ui.blue.buttons .button {
				background-color: #003065;
			}

			.ui.positive.button,
			.ui.positive.buttons .button,
			.ui.green.button,
			.ui.green.buttons .button {
				background-color: #009a36;
			}

			.ui.orange.button,
			.ui.orange.buttons .button,
			.ui.red.button,
			.ui.red.buttons .button {
				background-color: #e2001a;
			}

			.ui.menu .teal.active.item,
			.ui.teal.menu .active.item,
			.ui.basic.teal.button,
			.ui.basic.teal.buttons .button {
				color: #003065 !important;
			}

			.ui.green.header,
			.ui.green.statistic > .value,
			.ui.green.statistics .statistic > .value,
			.ui.statistics .green.statistic > .value,
			.ui.basic.green.button,
			.ui.basic.green.buttons .button {
				color: #009a36 !important;
			}

			.ui.orange.statistic > .value,
			.ui.orange.statistics .statistic > .value,
			.ui.statistics .orange.statistic > .value,
			.ui.basic.orange.button,
			.ui.basic.orange.buttons .button {
				color: #e2001a !important;
			}

			.ui.menu .teal.active.item,
			.ui.teal.menu .active.item {
				border-color: #003065 !important;
			}
		`}
	/>
);

export default GlobalStyles;
