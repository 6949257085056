import * as T from 'modules/envelopes/_envelopesTypes';

import { fetch } from 'utils/utils';
import { formHasError } from 'utils/form';

export const updateFacility = async (id: number, facility: T.Facility) => {
	try {
		const response = await fetch(`/sarezaWeb/api/facility/${id}`, {
			method: 'PUT',
			headers: new Headers({ 'Content-Type': 'application/json' }),
			body: JSON.stringify(facility),
		});

		const hasErrors = formHasError(response);

		if (hasErrors) {
			return false;
		} else {
			return true;
		}
	} catch (err) {
		return false;
	}
};
