import React, { useMemo, useEffect } from 'react';
import { addYears, format } from 'date-fns';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getFormValues, reduxForm } from 'redux-form';
import { compact, each, get, isArray, isBoolean } from 'lodash-es';
import {
	Button,
	Divider,
	Form,
	Grid,
	Modal,
	Dropdown,
} from 'semantic-ui-react';
import { compose, lifecycle, withHandlers, withState } from 'recompose';

import { FormInput, Input } from 'components/form/input/Input';
import { FormSelect, Select } from 'components/form/select/Select';
import { Checkbox, FormCheckbox } from 'components/form/checkbox/Checkbox';
import { FormTextArea, TextArea } from 'components/form/textArea/TextArea';

import { User } from 'modules/profile/_profileTypes';

import { notEmpty, unique } from 'utils/validate';
import * as c from 'utils/constants';
import { CLIENT } from 'utils/constants';
import { hasAuthority, isLoggedIn } from 'utils/utils';

import * as T from './_envelopesTypes';
import {
	getActivities,
	getEnvelope,
	getEnvelopes,
	getFacilities,
	isPernamentType,
	getCards,
	getUsers,
	getAllAccounts,
} from './_envelopesActions';

const INIT_FORM_VALUE: Partial<T.EnvelopeFormValues> = {
	name: 'Uživatelské konto',
	activities: [],
	activityAll: true,
	facilities: [],
	facilityAll: true,
};

const isEnvelopeUnique = (
	value: string,
	allValues: any,
	{ envelopesNames, t, ...props }: { envelopesNames: Array<string>; t: any },
) => {
	const _unique = unique(value, envelopesNames);
	return _unique ? t(_unique, { name: value }) : undefined;
};

const adapter = (array: Array<any>) =>
	compact(
		array.map((_: any, id: any) => ({
			id,
		})),
	);

const usersAdapter = (array: Array<User>) =>
	array.map((user: User) => ({
		value: user.id,
		text: `${user.firstName} ${user.lastName}`,
		id: user.id,
	}));

const convertDateForApi = (date: string) => date.split('.').reverse().join('-');

const convertTypeForApi = (typeId: number) =>
	[
		{
			id: 1,
			name: 'Obecné – tvoří se automaticky, vždy platné',
		},
		{
			id: 2,
			name: 'Uživatelské',
		},
		{
			id: 3,
			name: 'Dodavatelské',
		},
		{
			id: 4,
			name: 'Ukončeno – ukončena platnost dodavatelského konta',
		},
		{
			id: 5,
			name: 'Smazáno/odstraněno – neviditelné pro uživatele',
		},
	][typeId - 1];

const mapItemsFromApiToRedux = (
	entity: T.Envelope,
	key: string,
	arr: boolean[],
) => {
	each(get(entity, key, []), f => (arr[f.id] = true));
	return arr;
};

const enhance = compose<T.EnvelopeForm, Partial<T.Envelopes>>(
	withTranslation('Envelopes'),
	withState<{}, boolean, string, string>('loader', 'setLoader', false),
	withState<{}, boolean, string, string>(
		'advanceSettingsVisible',
		'setAdvanceSettingsVisibility',
		false,
	),
	withState<{}, Array<T.Activity>, string, string>(
		'activities',
		'setActivities',
		[],
	),
	withState<{}, Array<T.Facility>, string, string>(
		'facilities',
		'setFacilities',
		[],
	),
	withState<{}, Array<T.Card>, string, string>('cards', 'setCards', []),
	withState<{}, Array<User>, string, string>('users', 'setUsers', []),
	withState<{}, Array<string>, string, string>(
		'envelopesNames',
		'setEnvelopesNames',
		[''],
	),
	withHandlers<T.EnvelopeHandlers, Partial<T.EnvelopeHandlers>>({
		onSubmit: ({ saveAndClose }) => async (values: any) => {
			values.activityAll = !!values.activityAll;
			values.facilityAll = !!values.facilityAll;
			values.facilities = values.facilityAll
				? []
				: adapter(values.facilities || []);
			values.activities = values.activityAll
				? []
				: adapter(values.activities || []);
			values.validityFrom = convertDateForApi(values.validityFrom);
			values.validityTo = convertDateForApi(values.validityTo);
			values.type = convertTypeForApi(values.typeId);
			delete values.typeId;
			// values.userId = JSON.parse(storage.get('token')).id;
			values.blockByUser = false;
			if (isLoggedIn && hasAuthority(c.ADMIN)) {
				values.blockByAdmin = false;
				values.accountId = 1;
			}

			await saveAndClose(values);
		},
	}),
	reduxForm<T.EnvelopeFormValues>({
		form: 'envelopeForm',
		initialValues: INIT_FORM_VALUE,
	}),
	connect((state: any) => ({
		envelope: getFormValues('envelopeForm')(state),
	})),
	lifecycle<T.EnvelopeForm, any>({
		async componentDidMount() {
			// FIXME: Implement CANCELABLE PROMISE, setState can occur even if the component is disconnected.
			const {
				setLoader,
				setActivities,
				setFacilities,
				initialize,
				setEnvelopesNames,
				setCards,
				// setUsers
			} = this.props;
			const { id, opened_form, loader } = this.props;

			if (!opened_form) {
				initialize(INIT_FORM_VALUE);
				return;
			}

			const promises = [];

			if (opened_form && !loader) {
				setLoader(true);

				if (id) {
					/**
					 * EDIT ENVELOPE
					 */
					const envelopePromise: any = getEnvelope(id).then(
						(envelope: T.Envelope | boolean) => {
							if (isBoolean(envelope)) {
								return;
							}

							envelope.typeId = envelope?.type?.id;
							delete envelope.type;
							const mappedFacilities: boolean[] = mapItemsFromApiToRedux(
								envelope,
								'facilities',
								[],
							);
							const mappedActivities: boolean[] = mapItemsFromApiToRedux(
								envelope,
								'activities',
								[],
							);
							initialize({
								...envelope,
								facilities: mappedFacilities,
								activities: mappedActivities,
							});
						},
					);

					promises.push(envelopePromise);
				} else {
					/**
					 * ADD NEW ENVELOPE
					 */

					const envelopesPromise = getEnvelopes().then(response => {
						if (isArray(response)) {
							setEnvelopesNames(response.map(e => e.name.toLowerCase()));
						}

						const min = format(new Date(), 'dd.MM.yyyy');
						const max = format(addYears(new Date(), 2), 'dd.MM.yyyy');

						initialize({
							...INIT_FORM_VALUE,
							validityFrom: min,
							validityTo: max,
						});
					});

					promises.push(envelopesPromise);
				}

				const activitiesPromise = getActivities().then(
					activities => isArray(activities) && setActivities(activities),
				);
				const facilitiesPromise = getFacilities().then(
					facilities => isArray(facilities) && setFacilities(facilities),
				);
				const cardsPromise = getCards().then(
					cards => isArray(cards) && setCards(cards),
				);

				promises.push(activitiesPromise);
				promises.push(facilitiesPromise);
				promises.push(cardsPromise);

				Promise.all(promises).then(() => setLoader(false));
			}
		},
	}),
	withHandlers({
		searchUsers: ({
			envelope: { searchedUser = '*' },
			setLoader,
			setUsers,
		}: any) => async (e: any) => {
			e.preventDefault();
			setLoader(true);
			await getUsers(searchedUser).then(
				users => {
					if (isArray(users)) {
						setUsers(users);
					} else {
						setUsers(null);
					}
				},
				() => {
					setUsers(null);
				},
			);
			setLoader(false);
		},
		fetchAccountsAndCardsForUser: ({ setLoader }) => async (id: number) => {
			// TODO: Filter cards and accounts by user
			setLoader(true);
			const [cards, accounts] = await Promise.all([
				getCards(),
				getAllAccounts(),
			]);
			console.debug(cards, accounts);
			setLoader(false);
		},
	}),
);

const View: React.FC<T.EnvelopeForm> = ({
	opened_form,
	close,
	loader,
	id,
	facilities,
	activities,
	envelope,
	handleSubmit,
	cards,
	searchUsers,
	t,
	users,
	fetchAccountsAndCardsForUser,
	advanceSettingsVisible,
	setAdvanceSettingsVisibility,
	change,
}) => {
	const allowedActivities = useMemo(
		() =>
			envelope.facilityAll
				? activities
				: envelope.facilities.flatMap((e, i) =>
						e ? facilities[i]?.activities : [],
				  ),
		[envelope.facilityAll, envelope.facilities, facilities, activities],
	);

	// Uncheck activities that are no longer viable after facilities change
	useEffect(() => {
		change(
			'activities',
			envelope.activities.map(
				(a, i) => a && Boolean(allowedActivities.find(aa => aa.id === i)),
			),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allowedActivities]);

	// Uncheck all activities if all facilities is unchecked
	useEffect(() => {
		!envelope.facilityAll && change('activityAll', false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [envelope.facilityAll]);

	return (
		<Modal
			open={opened_form}
			onClose={() => close('form')}
			className="envelopeForm"
		>
			<Modal.Header>
				{get(envelope, 'name', false) ? envelope.name : t('NEW_ENVELOPE')}
			</Modal.Header>
			<Modal.Content scrolling>
				<Modal.Description>
					<Form loading={loader} onSubmit={handleSubmit}>
						<Grid columns="equal" stackable padded="vertically">
							<Grid.Row>
								<Grid.Column>
									<FormInput
										name="name"
										label={t('ENVELOPE_NAME')}
										icon="mail outline"
										placeholder=""
										width={8}
										component={Input}
										validate={[notEmpty, isEnvelopeUnique]}
										disabled={isPernamentType(envelope)}
									/>
									{id && (
										<FormInput
											name="balanceAmount"
											label={t('AMOUNT')}
											icon="money bill alternate"
											placeholder=""
											width={8}
											component={Input}
											disabled
										/>
									)}
									<FormTextArea
										name="description"
										label={t('DESCRIPTION')}
										placeholder=""
										width={12}
										component={TextArea}
									/>
								</Grid.Column>
								<Grid.Column>
									{isLoggedIn && !hasAuthority(CLIENT) && (
										<>
											<Grid.Row style={{ marginBottom: 15 }}>
												<FormInput
													name="validityFrom"
													label={t('VALIDITY_FROM')}
													icon="time"
													placeholder=""
													width={8}
													component={Input}
													validate={notEmpty}
													disabled
												/>
											</Grid.Row>
											<Grid.Row style={{ marginBottom: 15 }}>
												<FormInput
													name="validityTo"
													label={t('VALIDITY_TO')}
													icon="time"
													placeholder=""
													width={8}
													component={Input}
													validate={notEmpty}
													disabled
												/>
											</Grid.Row>
										</>
									)}

									{isLoggedIn && hasAuthority(c.ADMIN) && (
										<>
											<Grid.Row style={{ marginBottom: 15 }}>
												<FormSelect
													name="typeId"
													placeholder={t('ACC_TYPE')}
													component={Select}
													validate={notEmpty}
													options={[
														{
															value: 1,
															text:
																'Obecné – tvoří se automaticky, vždy platné',
															key: 1,
														},
														{
															value: 2,
															text: 'Uživatelské',
															key: 2,
														},
														{
															value: 3,
															text: 'Dodavatelské',
															key: 3,
														},
														{
															value: 4,
															text:
																'Ukončeno – ukončena platnost dodavatelského konta',
															key: 4,
														},
														{
															value: 5,
															text:
																'Smazáno/odstraněno – neviditelné pro uživatele',
															key: 5,
														},
													]}
												/>
											</Grid.Row>
											<Grid.Row style={{ marginBottom: 15 }}>
												<Form.Group
													inline
													style={{ marginBottom: '.28571429rem' }}
												>
													<label>{t('SEARCH_USER')}</label>
												</Form.Group>
												<Form.Group inline>
													<FormInput
														name="searchedUser"
														placeholder=""
														component={Input}
													/>
													<Button color="orange" onClick={searchUsers}>
														{t('SEARCH')}
													</Button>
												</Form.Group>
												{users.length ? (
													<div>
														<Dropdown
															placeholder="Vyberte"
															selection
															onChange={(e: any, { value }: any) => {
																fetchAccountsAndCardsForUser(value);
															}}
															options={usersAdapter(users)}
														/>
													</div>
												) : null}
											</Grid.Row>
										</>
									)}
									{advanceSettingsVisible && (
										<Grid columns="equal">
											<Grid.Row style={{ marginBottom: 15 }}>
												{isLoggedIn && hasAuthority(c.ADMIN) && (
													<Grid.Column width="4">
														<FormSelect
															name="account"
															placeholder={t('ACCOUNT')}
															component={Select}
															validate={notEmpty}
															options={cards}
														/>
													</Grid.Column>
												)}
												<Grid.Column width="4">
													<FormSelect
														name="cards[]"
														placeholder={t('CARD')}
														component={Select}
														validate={notEmpty}
														options={cards}
													/>
												</Grid.Column>
											</Grid.Row>
										</Grid>
									)}
								</Grid.Column>
							</Grid.Row>
						</Grid>

						{!advanceSettingsVisible && (
							<div className="ui vertical labeled icon buttons">
								<button
									className="ui button mini"
									onClick={() => setAdvanceSettingsVisibility(true)}
								>
									<i className="angle double down icon" />
									{t('ADVANCE_SETTINGS')}
								</button>
							</div>
						)}

						{advanceSettingsVisible && (
							<>
								<Divider horizontal>{t('LIMITS')}</Divider>

								<Grid columns="equal" stackable padded="vertically">
									<Grid.Row>
										<Grid.Column width="4">
											<Grid.Row style={{ marginBottom: 15 }}>
												<FormCheckbox
													name="facilityAll"
													label={t('ALL_FACILITIES')}
													component={Checkbox}
													disabled={isPernamentType(envelope)}
												/>
											</Grid.Row>
											{facilities.map((item: T.Facility, index: number) => (
												<Grid.Row style={{ marginBottom: 5 }} key={index}>
													<FormCheckbox
														name={`facilities.${item.id}`}
														label={`${get(item, 'name', '') || ''}`}
														component={Checkbox}
														disabled={
															get(envelope, 'facilityAll', false) ||
															isPernamentType(envelope)
														}
													/>
												</Grid.Row>
											))}
										</Grid.Column>
										<Grid.Column width="12">
											<Grid.Row style={{ marginBottom: 15 }}>
												<FormCheckbox
													name="activityAll"
													label={t('ALL_ACTIVITIES')}
													component={Checkbox}
													disabled={
														!get(envelope, 'facilityAll', false) ||
														isPernamentType(envelope)
													}
												/>
											</Grid.Row>
											<Grid columns="equal" style={{ marginTop: 0 }}>
												{activities.map((item: T.Activity, index: number) => (
													<Grid.Column
														key={index}
														style={{
															marginBottom: 5,
															paddingTop: 0,
															paddingBottom: 0,
														}}
														width="5"
													>
														<FormCheckbox
															name={`activities.${item.id}`}
															label={item.name}
															component={Checkbox}
															disabled={
																get(envelope, 'activityAll', true) ||
																!allowedActivities.find(
																	a => a?.id === item?.id,
																) ||
																isPernamentType(envelope)
															}
														/>
													</Grid.Column>
												))}
											</Grid>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</>
						)}
					</Form>
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button color="orange" onClick={() => close('form')}>
					{t('CLOSE')}
				</Button>
				<Button
					positive
					onClick={handleSubmit}
					icon="checkmark"
					labelPosition="right"
					content={id ? t('SAVE') : t('CREATE')}
				/>
			</Modal.Actions>
		</Modal>
	);
};

export const EnvelopeForm = enhance(View);
