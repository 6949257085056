/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { Provider, connect } from 'react-redux';
import { Sidebar } from 'semantic-ui-react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { compose, withStateHandlers } from 'recompose';
import { ThemeProvider } from 'emotion-theming';

import { SarezaSidebar } from 'components/sidebar/Sidebar';
import { Appbar } from 'components/appbar/Appbar';
import Footer from 'components/Footer';

import { Login } from 'modules/login/Login';
import { Registration } from 'modules/registration/Registration';
import { ConfirmRegistration } from 'modules/registration/ConfirmRegistration';
import { ForgottenPassword } from 'modules/forgottenPassword/ForgottenPassword';
import { Envelopes } from 'modules/envelopes/Envelopes';
import { Transactions } from 'modules/transactions/Transactions';
import { Trend } from 'modules/trend/Trend';
import { Cards } from 'modules/cards/Cards';
import { News } from 'modules/news/News';
import { Profile } from 'modules/profile/Profile';
import { Password } from 'modules/profile/Password';
import { Homepage } from 'modules/homepage/Homepage';
import { Facilities } from 'modules/facilities/Facilities';
import { FacilityDetail } from 'modules/facilityDetail/FacilityDetail';
import { Admin } from 'modules/admin/Admin';
import { getUserInfo } from 'modules/login/_loginActions';
import { FacilityForm } from 'modules/admin/facilities/FacilityForm';

import 'utils/constants/translations';
import { theme } from 'theme';

import * as c from 'utils/constants';

import { hasAuthority, isLoggedIn } from './utils/utils';
import { ChangeForgottenPassword } from './modules/forgottenPassword/ChangeForgottenPassword';
import GlobalStyles from './theme/GlobalStyles';

import { Flex, Box } from 'components/styled';

// const SidebarPushable = mapProps<any, any>(({ computedMatch, location, ...rest }) => rest)(Sidebar.Pushable);

const enhance = compose<any, any>(
	withStateHandlers<any, any>(
		{ sidebarOpened: false },
		{
			toggleSidebar: () => (opened: boolean) => ({ sidebarOpened: opened }),
		},
	),
	connect(null, { AGetUserInfo: getUserInfo }),
);

const View: React.FC<any> = ({ store, sidebarOpened, toggleSidebar }) => (
	<ThemeProvider theme={theme}>
		<Provider store={store}>
			<Router>
				<GlobalStyles />
				<Appbar toggleSidebar={toggleSidebar} sidebarOpened={sidebarOpened} />
				<Sidebar.Pushable
					css={css`
						padding-top: 4.5rem !important;
						overflow: hidden;
					`}
				>
					<SarezaSidebar opened={sidebarOpened} toggleSidebar={toggleSidebar} />
					<Flex
						as={Sidebar.Pusher}
						flexDirection="column"
						height="100%"
						css={css`
							overflow: auto !important;
						`}
					>
						<Box flexGrow={1} px="3rem" py="1rem">
							{isLoggedIn && <div className="sareza-logo" />}
							<Switch>
								{isLoggedIn ? (
									<Fragment>
										<Fragment>
											<Route exact path="/" component={Homepage} />
											<Route exact path="/user/edit" component={Profile} />
											<Route exact path="/user/password" component={Password} />
											<Route
												exact
												path="/account/envelopes"
												component={Envelopes}
											/>
											<Route
												exact
												path="/account/transactions"
												component={Transactions}
											/>
											<Route exact path="/account/trend" component={Trend} />
											<Route exact path="/cards/my-cards" component={Cards} />
											<Route exact path="/news/my-news" component={News} />
											<Route
												exact
												path="/facilities/all"
												component={Facilities}
											/>
											<Route
												exact
												path="/detail/:facility"
												component={FacilityDetail}
											/>
											<Route
												exact
												path="/facility/:facility"
												component={FacilityForm}
											/>
										</Fragment>

										{hasAuthority(c.ADMIN) && (
											<Fragment>
												<Route exact path="/admin" component={Admin} />
												<Route
													exact
													path="/admin/facility/:id"
													component={FacilityForm}
												/>
											</Fragment>
										)}
									</Fragment>
								) : (
									<Fragment>
										<Route exact path="/" component={Login} />
										<Route exact path="/login" component={Login} />
										<Route
											exact
											path="/registration"
											component={Registration}
										/>
										<Route
											exact
											path="/confirmRegistration"
											component={ConfirmRegistration}
										/>
										<Route
											exact
											path="/forgotten-password"
											component={ForgottenPassword}
										/>
										<Route
											exact
											path="/changeForgottenPassword"
											component={ChangeForgottenPassword}
										/>
									</Fragment>
								)}
							</Switch>
						</Box>
						<Footer />
					</Flex>
				</Sidebar.Pushable>
			</Router>
		</Provider>
	</ThemeProvider>
);

export default enhance(View);
