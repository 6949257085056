/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import { Field, GenericField } from 'redux-form';
import ReCAPTCHA from 'react-google-recaptcha';

import { sitekey } from 'utils/constants/captcha';

import * as T from './_recaptchaTypes';

let captcha: any;

const Recaptcha: React.FC<T.OutterProps & T.InnerProps> = props => (
	<div style={{ maxWidth: '100%', overflow: 'hidden', textAlign: 'center' }}>
		<ReCAPTCHA
			{...props}
			sitekey={sitekey}
			onChange={props.input.onChange}
			ref={(input: any) => {
				captcha = input;
			}}
			css={css`
				display: inline-block;
			`}
		/>

		<span
			style={{
				color: '#9f3a38',
				display: props.meta.touched && props.meta.error ? 'block' : 'none',
			}}
		>
			{props.meta.touched && props.meta.error}
		</span>
	</div>
);

export const resetCaptcha = () => captcha.reset();

export default Recaptcha;

export const RecaptchaField = Field as new () => GenericField<
	T.CustomFieldProps
>;
