import React from 'react';
import { get } from 'lodash-es';
import * as queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { Container, Segment, Loader, Dimmer } from 'semantic-ui-react';
import { compose, lifecycle } from 'recompose';

import { confirmRegistration } from './_registrationActions';

const enhance = compose(
	withRouter,
	lifecycle<any, any>({
		async componentDidMount() {
			const { location, history } = this.props;

			const params = queryString.parse(get(location, 'search'));
			const token = get(params, 'token', '');

			const response = await confirmRegistration(token as any);

			if (response) {
				history.push({
					pathname: '/login',
					state: {
						isConfirmed: {
							error: false,
						},
					},
				});
			} else {
				history.push({
					pathname: '/login',
					state: {
						isConfirmed: {
							error: true,
						},
					},
				});
			}
		},
	}),
);

const View: React.FC<any> = ({ message }) => (
	<Segment
		textAlign="center"
		style={{
			minHeight: '100%',
			padding: '1em 0em',
			display: 'flex',
			alignItems: 'center',
		}}
		vertical
	>
		<Container text>
			<Dimmer active>
				<Loader />
			</Dimmer>
		</Container>
	</Segment>
);

export const ConfirmRegistration = enhance(View);
