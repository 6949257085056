import { each, includes, get, trim, filter } from 'lodash-es';

export const notEmpty = (v: any) => (v ? undefined : 'Povinné pole');

export const isNumber = (v: any) =>
	parseInt(v, 10) ? undefined : 'Musí být číslo';

export const unique = (v: any, all: Array<any>) =>
	all.includes(v.toLowerCase()) ? 'NOT_UNIQUE' : undefined;

export const validators: any = {
	email: (value: string) => {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return !re.test(value) ? 'EMAIL_WRONG' : false;
	},
	passwordsMatch: (values: any, fields: any) => {
		const p1 = fields[0];
		const p2 = fields[1];
		if (
			get(values, get(p1, 'id')) &&
			get(values, get(p2, 'id')) &&
			get(values, get(p1, 'id')) !== get(values, get(p2, 'id'))
		) {
			return 'PASSWORDS_NO_MATCH';
		} else {
			return null;
		}
	},
	passwordMinLength: (value: any) =>
		trim(value).length < 8 ? 'PASSWORD_MIN_LENGTH' : false,
	passwordGoodLength: (value: any) =>
		trim(value).length >= 8 && trim(value).length < 10
			? 'PASSWORD_GOOD_LENGTH'
			: false,
	passwordExcellentLength: (value: any) =>
		trim(value).length >= 10 ? 'PASSWORD_EXCELLENT_LENGTH' : false,
	required: (value: any) => (!value ? 'REQUIRED' : false),
};

export const basicFormValidator = (values: any, { fields, t }: any) => {
	const errors: any = {};

	const passwords = filter(fields, { matchPasswords: true });
	if (passwords.length === 2) {
		const error = validators.passwordsMatch(values, passwords);
		if (error) {
			errors[get(passwords, '[1].id')] = t(error);
		}
	}

	each(fields, field => {
		each(validators, (validatorFn, validatorName) => {
			if (
				includes(field.validations, validatorName) &&
				validatorFn(get(values, field.id))
			) {
				errors[field.id] = t(validatorFn(get(values, field.id)));
			}
		});
	});

	return errors;
};
