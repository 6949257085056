import React from 'react';
import MediaQuery from 'react-responsive';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { compose, withState, withHandlers, withProps } from 'recompose';
import { SubmissionError, Field, reduxForm, change } from 'redux-form';
import { Button, Form, Grid, Message, Divider } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { FormInput, Input } from 'components/form/input/Input';
import { FormPassword, Password } from 'components/form/input/Password';
import Recaptcha, { resetCaptcha } from 'components/form/recaptcha/Recaptcha';
import { SuccessfulMessage } from 'components/messages/SuccessfulMessage';
import { ErrorMessage } from 'components/messages/ErrorMessage';

import { notEmpty } from 'utils/validate';
import style from 'utils/style';
import * as c from 'utils/constants/index';

import * as T from './_loginTypes';
import { loginUser } from './_loginActions';

const unsuccessfullLoginCountToWhowCaptcha = 1;

const enhance = compose<T.FormInner, T.FormOutter>(
	withRouter,
	withTranslation('Login'),
	withState('loader', 'setLoader', false),
	withState('unsuccessfullLoginCount', 'incrementUnsuccessfullLoginCount', 0),
	connect(null, (dispatch: any) => ({
		resetReduxCaptcha: () => dispatch(change('loginForm', 'recaptcha', null)),
	})),
	withProps(({ location }: any) => ({
		isRegistered: !!location?.state?.isRegistered,
		isConfirmed: location?.state?.isConfirmed
			? {
					error: !!location.state.isConfirmed.error,
			  }
			: false,
	})),
	withHandlers<any, any>({
		onSubmit: ({
			history,
			setLoader,
			unsuccessfullLoginCount,
			incrementUnsuccessfullLoginCount,
			resetReduxCaptcha,
			t,
		}: any) => async (values: any) => {
			setLoader(true);

			if (unsuccessfullLoginCount >= unsuccessfullLoginCountToWhowCaptcha) {
				if (
					await loginUser(values.username, values.password, values.recaptcha)
				) {
					history.push('/account/envelopes');
					window.location.reload();
				} else {
					setLoader(false);
					resetCaptcha();
					resetReduxCaptcha('loginForm', 'recaptcha', '');
					incrementUnsuccessfullLoginCount(unsuccessfullLoginCount + 1);

					throw new SubmissionError({
						username: t('BAD_CREDENTIALS'),
						password: t('BAD_CREDENTIALS'),
					});
				}
			} else {
				if (await loginUser(values.username, values.password)) {
					history.push('/account/envelopes');
					window.location.reload();
				} else {
					setLoader(false);
					incrementUnsuccessfullLoginCount(unsuccessfullLoginCount + 1);

					throw new SubmissionError({
						username: t('BAD_CREDENTIALS'),
						password: t('BAD_CREDENTIALS'),
					});
				}
			}
		},
	}),
	reduxForm({ form: 'loginForm' }),
);

const View: React.FC<T.LoginForm> = ({
	loader,
	unsuccessfullLoginCount,
	handleSubmit,
	MyFormErrors,
	t,
	location,
	isRegistered,
	isConfirmed,
}) => (
	<div style={{ height: '100%' }}>
		<MediaQuery maxWidth={c.BREAKPOINTS.mobileMax}>
			{isMobileMax => (
				<Grid
					textAlign="center"
					style={{
						height: '100%',
						marginLeft: isMobileMax ? 0 : null,
						marginRight: isMobileMax ? 0 : null,
					}}
					verticalAlign="middle"
				>
					<Grid.Column
						mobile={15}
						tablet={7}
						computer={5}
						largeScreen={4}
						widescreen={3}
					>
						<Message attached>
							<Message.Header style={style('colors.text.primary')}>
								{t('LOGIN_MESSAGE')}
							</Message.Header>
						</Message>
						<Form
							size="small"
							className="attached fluid segment"
							loading={loader}
							onSubmit={handleSubmit}
						>
							<SuccessfulMessage
								enable={isRegistered}
								title={t('DONE')}
								description={t('REGISTRATION_SUCCESSFULL')}
							/>
							<SuccessfulMessage
								enable={Boolean(isConfirmed && !isConfirmed.error)}
								title={t('DONE')}
								description={t('REGISTRATION_CONFIRMED')}
							/>
							<ErrorMessage
								enable={Boolean(isConfirmed && !isConfirmed.error)}
								title={t('ERROR')}
								description={t('REGISTRATION_CONFIRMED_ERROR')}
							/>
							{t('FIRST_TIME')} <Link to="/registration">{t('REGISTER')}</Link>
							<Divider />
							<FormInput
								name="username"
								placeholder={t('USERNAME')}
								icon="user"
								component={Input}
								validate={notEmpty}
								errorTextPosition="bottom"
							/>
							<FormPassword
								name="password"
								placeholder={t('PASSWORD')}
								icon="lock"
								component={Password}
								validate={notEmpty}
								errorTextPosition="bottom"
							/>
							{/* show captcha only when login is not successfull more then unsuccessfullLoginCountToWhowCaptcha times */}
							{unsuccessfullLoginCount >=
								unsuccessfullLoginCountToWhowCaptcha && (
								<Field
									name="recaptcha"
									component={Recaptcha}
									validate={notEmpty}
								/>
							)}
							<Divider hidden />
							<Button
								type="submit"
								fluid
								size="small"
								style={style('button.primary')}
							>
								{t('LOGIN')}
							</Button>
							<Divider />
							<Link to="/forgotten-password">{t('FORGOTTEN_PASSWORD')}</Link>
						</Form>
					</Grid.Column>
				</Grid>
			)}
		</MediaQuery>
	</div>
);

export const Login = enhance(View as any);
