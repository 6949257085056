import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Grid, Button } from 'semantic-ui-react';

import { FormInput, Input } from 'components/form/input/Input';
import { FormDate, Date } from 'components/form/input/Date';

import { notEmpty } from 'utils/validate';

import * as T from './_profileTypes';

const enhance = compose<any, Partial<T.ProfileBasicForm>>(
	withTranslation('Profile'),
);

const View: React.FC<T.ProfileBasicForm> = ({ t, handleSubmit }) => (
	<Grid columns={2}>
		<Grid.Row>
			<Grid.Column mobile={16} computer={8} widescreen={6}>
				{[
					{
						name: 'username',
						label: t('LOGIN'),
						icon: 'user',
						validate: notEmpty,
					},
					{ name: 'firstName', label: t('FIRST_NAME'), validate: notEmpty },
					{ name: 'middlename', label: t('MIDDLE_NAME') },
					{ name: 'lastName', label: t('LAST_NAME'), validate: notEmpty },
					{ name: 'titleBefore', label: t('TITLE_BEFORE'), icon: 'graduation' },
					{ name: 'titleAfter', label: t('TITLE_AFTER'), icon: 'graduation' },
				].map(field => (
					<FormInput
						{...field}
						width={10}
						placeholder=""
						key={field.name}
						component={Input}
					/>
				))}
				<div style={{ marginTop: 20 }}>
					<Button
						color="teal"
						onClick={handleSubmit}
						content={t('SAVE_PROFILE')}
					/>
				</div>
			</Grid.Column>
			<Grid.Column mobile={16} computer={8} widescreen={6}>
				<FormDate
					name="birthDate"
					width={10}
					label={t('BIRTHDATE')}
					placeholder=""
					icon="birthday cake"
					component={Date}
				/>
				{[
					{ name: 'phone', label: t('PHONE'), icon: 'phone' },
					{
						name: 'email',
						label: t('EMAIL'),
						icon: 'mail outline',
						validate: notEmpty,
					},
				].map(field => (
					<FormInput
						{...field}
						width={10}
						placeholder=""
						key={field.name}
						component={Input}
					/>
				))}
			</Grid.Column>
		</Grid.Row>
	</Grid>
);

export default enhance(View);
