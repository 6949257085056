import { USER_STORE } from 'utils/constants';

const initialState = {
	user: {},
};

const reducer = (state = initialState, action: any) => {
	switch (action.type) {
		case USER_STORE:
			return { ...state, user: action.payload };
		default:
			return state;
	}
};

export default reducer;
