import { get } from 'lodash-es';
import React from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { Button, Form, Grid, Message, Divider } from 'semantic-ui-react';
import { compose, withState, withHandlers, withPropsOnChange } from 'recompose'; // nice waterfall kobi :D // thank you Jurgen

import Recaptcha, { resetCaptcha } from 'components/form/recaptcha/Recaptcha';
import { FormInput, Input } from 'components/form/input/Input';
import { FormDate, Date } from 'components/form/input/Date';
import { FormPassword, Password } from 'components/form/input/Password';
import { FormCheckbox, Checkbox } from 'components/form/checkbox/Checkbox';
import Messages from 'components/form/messages/Messages';
import PasswordStrength from 'components/form/passwordStrength/PasswordStrength';

import style from 'utils/style';
import { basicFormValidator } from 'utils/validate';
import * as c from 'utils/constants/';

import { registerUser } from './_registrationActions';
import * as T from './_registrationTypes';

const enhance = compose<T.FormInner, T.FormOutter>(
	withRouter,
	withTranslation('Login'),
	withState('loader', 'setLoader', false),
	withState('msgs', 'setMessages', []),
	connect(null, { registerUser }),
	withHandlers<any, any>({
		onSubmit: ({ history, location, setLoader, setMessages, t }: any) => async (
			values: any,
		) => {
			setLoader(true);

			const response = await registerUser(values);
			setMessages(get(response, 'msgs', []));
			if (!response || get(response, 'hasErrors')) {
				resetCaptcha();
			}

			setLoader(false);

			if (response.hasErrors) {
				setMessages(
					response.msgs.map(message => ({
						code: 'ERROR',
						msg: `${message.args
							.map(arg => t(arg.toUpperCase()))
							.join(',')} ${t(message.msg)}`,
					})),
				);
			} else {
				history.push({
					pathname: '/login',
					state: {
						isRegistered: true,
					},
				});
			}
		},
	}),
	withPropsOnChange([], () => ({
		fields: [
			{ id: 'email', validations: ['required', 'email'] },
			{ id: 'username', validations: ['required'] },
			{
				id: 'password1',
				validations: ['required', 'passwordMinLength'],
				matchPasswords: true,
			},
			{
				id: 'password2',
				validations: ['required', 'passwordMinLength'],
				matchPasswords: true,
			},
			{ id: 'birthDate', validations: ['required'] },
			{ id: 'eula', validations: ['required'] },
			{ id: 'recaptcha', validations: ['required'] },
		],
	})),
	reduxForm({ form: 'registrationForm', validate: basicFormValidator }),
	connect(state => {
		const values = getFormValues('registrationForm')(state);
		return {
			password: get(values, 'password1'),
		};
	}),
);

const View: React.FC<T.LoginForm> = ({
	loader,
	handleSubmit,
	password,
	msgs,
	t,
}) => (
	<div style={{ height: '100%' }}>
		<MediaQuery maxWidth={c.BREAKPOINTS.mobileMax}>
			{isMobileMax => (
				<Grid
					textAlign="center"
					style={{
						minHeight: '100%',
						padding: '88px 0',
						...{
							marginLeft: isMobileMax ? 0 : null,
							marginRight: isMobileMax ? 0 : null,
						},
					}}
					verticalAlign="middle"
				>
					<Grid.Column
						mobile={15}
						tablet={7}
						computer={5}
						largeScreen={4}
						widescreen={3}
					>
						<Message attached>
							<Message.Header style={style('colors.text.primary')}>
								{t('REGISTRATION_TITLE')}
							</Message.Header>
						</Message>
						<Form
							size="small"
							className="attached fluid segment"
							loading={loader}
							onSubmit={handleSubmit}
						>
							{t('ALREADY_REGISTERED')}{' '}
							<Link to="/login">{t('LOGIN_ACTION')}</Link>
							<Divider />
							<FormInput
								errorTextPosition="bottom"
								name="email"
								placeholder={t('EMAIL')}
								icon="mail"
								component={Input}
							/>
							<FormInput
								errorTextPosition="bottom"
								name="username"
								placeholder={t('USERNAME')}
								icon="user"
								component={Input}
							/>
							<FormPassword
								errorTextPosition="bottom"
								name="password1"
								placeholder={t('PASSWORD')}
								icon="lock"
								component={Password}
							/>
							<FormPassword
								errorTextPosition="bottom"
								name="password2"
								placeholder={t('PASSWORD_AGAIN')}
								icon="lock"
								component={Password}
							/>
							<div style={{ marginBottom: 15 }}>
								<PasswordStrength password={password} />
							</div>
							<FormDate
								errorTextPosition="bottom"
								name="birthDate"
								label={t('BIRTHDATE')}
								placeholder=""
								icon="birthday cake"
								component={Date}
							/>
							<FormCheckbox
								name="eula"
								label={t('AGREEMENT')}
								component={Checkbox}
							/>
							<Field name="recaptcha" component={Recaptcha} />
							<Divider hidden />
							<Messages msgs={msgs as any} style={{ marginBottom: 10 }} />
							<Button
								type="submit"
								fluid
								size="small"
								style={style('button.primary')}
							>
								{t('REGISTER')}
							</Button>
						</Form>
					</Grid.Column>
				</Grid>
			)}
		</MediaQuery>
	</div>
);

export const Registration = enhance(View as any);
