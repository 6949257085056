import React from 'react';
import { get, isArray } from 'lodash-es';
import MediaQuery from 'react-responsive';
import { withTranslation } from 'react-i18next';
import { compose, lifecycle, withState } from 'recompose';
import { Header, Grid, Divider, Segment } from 'semantic-ui-react';
import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
} from 'recharts';

import * as c from 'utils/constants';

import { getTransactions, createChartDataset } from './_trendActions';

const enhance = compose(
	withTranslation('Trend'),
	withState('transactions', 'setTransactions', []),
	lifecycle<any, any>({
		async componentDidMount() {
			const { setTransactions } = this.props;
			const response = await getTransactions();

			if (response && get(response, 'data.rows')) {
				setTransactions(createChartDataset(response.data.rows));
			}
		},
	}),
);

const View: React.FC<any> = ({ transactions, t }) => (
	<>
		<Segment vertical>
			<Grid celled={false} padded="horizontally">
				<MediaQuery minWidth={c.BREAKPOINTS.desktopMin}>
					<Divider hidden />
				</MediaQuery>

				<Grid.Row>
					<Grid.Column computer={12} tablet={16}>
						<Header as="h1" floated="left">
							{t('TREND')}
						</Header>
					</Grid.Column>
				</Grid.Row>

				<Grid.Row>
					<Grid.Column computer={12} tablet={16}>
						{isArray(transactions) && transactions.length ? (
							<ResponsiveContainer height={600} width="100%">
								<AreaChart
									data={transactions}
									margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
								>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="transactionDate" />
									<YAxis dataKey="amount" />
									<Tooltip labelFormatter={value => t('BALANCE')} />
									<Area
										type="linear"
										dataKey="amount"
										stroke="#f2711c"
										strokeWidth="3px"
										fill="#21ba45"
									/>
								</AreaChart>
							</ResponsiveContainer>
						) : (
							<div>{t('NO_DATA')}</div>
						)}
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
	</>
);

export const Trend = enhance(View);
