/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { Fragment, useMemo } from 'react';
import { get } from 'lodash-es';
import MediaQuery from 'react-responsive';
import { withTranslation } from 'react-i18next';
import { differenceInDays, format } from 'date-fns';
import { Header, Table, Icon, Button, Popup } from 'semantic-ui-react';
import { compose } from 'recompose';

import { isPernamentType } from 'modules/envelopes/_envelopesActions';

import * as c from 'utils/constants/index';

import * as T from './_envelopesTypes';

const ehnance = compose<any, any>(withTranslation('Envelopes'));

const envelopeBelongsToUser = (envelope: T.Envelope) =>
	get(envelope, 'type.id') === c.ENVELOPE_TYPE_USER;

const envelopeCanBeToppedUp = (envelope: T.Envelope) =>
	envelopeBelongsToUser(envelope);

const envelopeCanBeDeletedByUser = (envelope: T.Envelope) =>
	get(envelope, 'type.id') !== c.ENVELOPE_TYPE_GENERAL;

const accountIconColor = (envelope: T.Envelope) => {
	if (envelope.balanceAmount === 0) {
		return 'red';
	}

	if (envelope.activityAll && envelope.facilityAll) {
		return 'green';
	}

	return 'orange';
};

const MIN_ACCOUNT_DAYS_TO_WARNING = 30;
const TYPE_GENERAL_ENVELOPE_ID = 1;

const View: React.FC<T.EnvelopesTableItem> = ({ envelope, open, t }) => {
	const daysLeft = useMemo(
		() =>
			envelope.validityTo
				? differenceInDays(new Date(envelope.validityTo), new Date())
				: 0,
		[envelope.validityTo],
	);

	return (
		<Table.Row>
			<Table.Cell>
				<Header as="h4">
					<Popup
						trigger={
							<Icon
								name={envelopeBelongsToUser(envelope) ? 'user' : 'mail'}
								color={accountIconColor(envelope)}
							/>
						}
						content={t(
							envelopeBelongsToUser(envelope)
								? 'OWN_ENVELOPE'
								: 'FOREIGN_ENVELOPE',
						)}
					/>

					<Popup
						trigger={<Icon name={envelope.ticket ? 'ticket' : 'money'} />}
						content={t(envelope.ticket ? 'PASS_ENVELOPE' : 'PAYMENT_ENVELOPE')}
					/>
					<Header.Content
						css={
							envelope.type?.id === TYPE_GENERAL_ENVELOPE_ID &&
							css`
								font-style: italic;
							`
						}
					>
						{envelope.name}
					</Header.Content>
				</Header>
			</Table.Cell>
			<Table.Cell singleLine>{envelope.description}</Table.Cell>
			<Table.Cell singleLine>
				<MediaQuery maxWidth={c.BREAKPOINTS.mobileMax}>
					{t('ENVELOPE_BALANCE')}:{' '}
				</MediaQuery>
				{envelope.balanceAmount} Kč
			</Table.Cell>
			<Table.Cell singleLine>
				{envelope.validityTo && (
					<Fragment>
						<MediaQuery maxWidth={c.BREAKPOINTS.mobileMax}>
							{t('ENVELOPE_VALIDITY')}:{' '}
						</MediaQuery>
						{daysLeft <= MIN_ACCOUNT_DAYS_TO_WARNING && (
							<Popup
								content={t('ACCOUNT_REMAINS_DAY', { days: daysLeft })}
								trigger={<Icon name="warning sign" color="orange" />}
								position="top center"
							/>
						)}
						<Popup
							trigger={
								<span>
									{t('DAYS', {
										count: daysLeft,
									})}
								</span>
							}
							content={format(new Date(envelope.validityTo), 'dd.mm.yyyy')}
						/>
					</Fragment>
				)}
			</Table.Cell>
			<Table.Cell textAlign="right" style={{ whiteSpace: 'nowrap' }}>
				{envelopeCanBeToppedUp(envelope) && !isPernamentType(envelope) ? (
					<Button
						color="green"
						compact
						size={'large'}
						basic
						className="button--envelope"
					>
						<Icon name="dollar sign" />
						{t('TOP_UP')}
					</Button>
				) : null}
				{envelopeCanBeDeletedByUser(envelope) && !isPernamentType(envelope) ? (
					<Button
						color="orange"
						onClick={() => open('confirm', envelope.id.toString())}
						compact
						size="large"
						basic
						className="button--envelope"
					>
						<Icon name="remove" />
						{t('DELETE')}
					</Button>
				) : null}
				{envelope.balanceAmount > 0 && (
					<Button
						size="large"
						color="teal"
						compact
						basic
						onClick={() => open('moveAmount', envelope.id.toString())}
						className="button--envelope"
					>
						<Icon name="resize horizontal" />
						{t('MOVE_AMOUNT')}
					</Button>
				)}
				<Button
					color="teal"
					onClick={() => open('form', envelope.id.toString())}
					compact
					size="large"
					basic
					className="button--envelope"
				>
					<Icon name="pencil" />
					{t('EDIT')}
				</Button>
			</Table.Cell>
		</Table.Row>
	);
};

export const EnvelopesTableItem = ehnance(View);
