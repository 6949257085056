// Basic functions with localStorage
export const put = (key: string, value: any) => {
	window.localStorage.setItem(key, value);
};
export const get = (key: string) => window.localStorage.getItem(key);
export const remove = (key: string) => window.localStorage.removeItem(key);
export const clear = () => {
	window.localStorage.clear();
};

// basic functions with sessionStorage
export const putSession = (key: string, value: any) => {
	window.sessionStorage.setItem(key, value);
};
export const getSession = (key: string) => window.sessionStorage.getItem(key);
export const removeSession = (key: string) =>
	window.sessionStorage.removeItem(key);
export const clearSession = () => {
	window.sessionStorage.clear();
};
