import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { List, Icon } from 'semantic-ui-react';

import { Flex } from './styled';

const Footer: FC = () => {
	const { t } = useTranslation('Footer');
	return (
		<Flex
			px={4}
			py={3}
			width={1}
			color="white"
			bg="primary"
			position="sticky"
			left={0}
			flexDirection={['column', 'row']}
		>
			<Flex pr={[0, 2]} pb={[3, 0]} width={[1, 1 / 3]}>
				<List inverted link>
					<List.Header>{t('LINKS')}</List.Header>
					<List.Item as="a" href="/documents/TermsAndConditions.docx" download>
						{t('TERMS_AND_CONDITIONS')}{' '}
						<Icon link name="external" size="tiny" />
					</List.Item>
					<List.Item as="a" href="/documents/GDPR.pdf" download>
						{t('GDPR')} <Icon link name="external" size="tiny" />
					</List.Item>
					<List.Item as="a" href="mailto:INFOCENTRUM@SAREZA.CZ">
						{t('REPORT_PROBLEMS')} <Icon link name="mail outline" size="tiny" />
					</List.Item>
				</List>
			</Flex>
			<Flex pl={[0, 2]} width={[1, 2 / 3]}>
				<List link inverted>
					<List.Header>{t('CONTACT')}</List.Header>
					<List.Item>
						Sportovní a rekreační zařízení města Ostravy s.r.o., Čkalovova
						6144/20, IČ: 25385691
					</List.Item>
					<List.Item>DIČ: CZ25385691</List.Item>
					<List.Item>
						Společnost je zapsána v obchodním rejstříku, vedeného Krajským
						soudem v Ostravě oddíl C, vložka 17345 Datová schránka: y9n3tvw
					</List.Item>
					<List.Item>Datová schránka: y9n3tvw</List.Item>
				</List>
			</Flex>
		</Flex>
	);
};
export default Footer;
