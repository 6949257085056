import React from 'react';
import MediaQuery from 'react-responsive';
import { withTranslation } from 'react-i18next';
import { Sidebar, Menu } from 'semantic-ui-react';
import { withRouter, NavLink } from 'react-router-dom';
import { compose, withHandlers } from 'recompose';

import { BREAKPOINTS } from 'utils/constants/index';
import { hasAuthority } from 'utils/utils';
import menuItems from 'utils/constants/menuItems';

const enhance = compose<any, any>(
	withRouter,
	withTranslation('Appbar'),
	withHandlers({
		renderMenuItem: ({ t, toggleSidebar }: any) => (
			item: any,
			padded = false,
		) => (
			<Menu.Item
				key={item.link}
				{...(item.link ? { as: NavLink, to: item.link } : {})}
				style={{
					textTransform: 'uppercase',
					border: 0,
					...(padded ? { paddingLeft: 30 } : {}),
				}}
				onClick={() => (item.submenu ? false : toggleSidebar(false))}
			>
				{t(item.label)}
			</Menu.Item>
		),
	}),
);

const View: React.FC<any> = ({ opened, renderMenuItem }) => (
	<MediaQuery maxWidth={BREAKPOINTS.tabletMax}>
		{matches => (
			<Sidebar
				className="sidebar-menu"
				animation="overlay"
				as={Menu}
				visible={matches && opened}
				vertical
				borderless
			>
				{menuItems.map((item: any) =>
					!item.restriction ||
					(item.restriction && hasAuthority(item.restriction)) ? (
						<React.Fragment key={item.link}>
							{renderMenuItem(item)}
							{item.submenu
								? item.submenu.map((submenuItem: any) =>
										renderMenuItem(submenuItem, true),
								  )
								: null}
						</React.Fragment>
					) : null,
				)}
			</Sidebar>
		)}
	</MediaQuery>
);

export const SarezaSidebar = enhance(View);
