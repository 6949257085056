import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Grid, Button } from 'semantic-ui-react';
import { FormSection } from 'redux-form';
import { identity } from 'lodash-es';

import { FormInput, Input } from 'components/form/input/Input';
import { FormSelect, Select } from 'components/form/select/Select';

import { notEmpty } from 'utils/validate';
import countries from 'utils/countries';

import * as T from './_profileTypes';

const enhance = compose<any, Partial<T.ProfileAddressForm>>(
	withTranslation('Profile'),
);

const View: React.FC<T.ProfileAddressForm> = ({ t, section, handleSubmit }) => (
	<Grid columns={1}>
		<Grid.Row>
			<Grid.Column mobile={16} computer={8} widescreen={6}>
				<FormSection name={section}>
					{[
						{ name: 'street', label: t('STREET'), validate: notEmpty },
						{ name: 'streetNumber1', label: t('STREET_NUMBER_DESCRIPTIVE') },
						{ name: 'streetNumber2', label: t('STREET_NUMBER_ORIENTATION') },
						{ name: 'city', label: t('CITY') },
						{ name: 'zip', label: t('ZIP') },
					].map(field => (
						<FormInput
							{...field}
							width={10}
							placeholder=""
							key={field.name}
							component={Input}
						/>
					))}
					<div style={{ width: '62.5%' }}>
						<FormSelect
							component={Select}
							name="country"
							placeholder={t('COUNTRY')}
							options={countries}
							fluid
							valueFunction={identity}
						/>
					</div>
				</FormSection>
				<div style={{ marginTop: 20 }}>
					<Button
						color="teal"
						onClick={handleSubmit}
						content={t('SAVE_PROFILE')}
					/>
				</div>
			</Grid.Column>
		</Grid.Row>
	</Grid>
);

export default enhance(View);
