import styled, { CreateStyled } from '@emotion/styled/macro';

export const TEXT_COLOR = '#003065';

export const theme = {
	colors: {
		primary: '#00244c',
		secondary: '#60bbce',
		text: TEXT_COLOR,
	},
	breakpoints: ['40em', '52em', '64em', '76em'],
	space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
};

export type Theme = typeof theme;

export default styled as CreateStyled<Theme>;
