import { lifecycle, compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import * as queryString from 'query-string';
import { get, includes, memoize } from 'lodash-es';
import decode from 'jwt-decode';

import * as c from 'utils/constants/index';

import { setBreadcrumbs } from './actions';
import customFetch from './fetch';

const noop = () => ({});

export const getQuery = (location: string) => {
	const search = get(location, 'search');
	return queryString.parse(search);
};

export const breadcrumbs = (value: any) =>
	compose(
		connect(noop, { setBreadcrumbs }),
		lifecycle<any, any>({
			componentDidMount() {
				this.props.setBreadcrumbs(value);
			},
		}),
	);

export const deleteCookie = (name: string) => {
	document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export const getCookie = (name: string): string | void => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) {
		return parts?.pop()?.split(';').shift();
	}
};

export const formWrapper = mapProps<any, any>(formProps => {
	const { input, meta, ...rest } = formProps;
	return {
		errorText: meta.touched && meta.error,
		value: input.value,
		onChange: input.onChange,
		name: input.name,
		...rest,
	};
});

export const arrayWrapper = mapProps<any, any>(formProps => {
	const { input, meta, ...rest } = formProps;
	return {
		errorText: meta.touched && meta.error,
		value: input.value || [],
		onChange: input.onChange,
		name: input.name,
		...rest,
	};
});

export const nullWrapper = mapProps<any, any>(formProps => {
	const { input, meta, ...rest } = formProps;
	return {
		errorText: meta.touched && meta.error,
		value: input.value || null,
		onChange: input.onChange,
		name: input.name,
		...rest,
	};
});

export const booleanWrapper = mapProps<any, any>(formProps => {
	const { input, meta, ...rest } = formProps;
	return {
		errorText: meta.touched && meta.error,
		value: !!input.value,
		onChange: input.onChange,
		name: input.name,
		...rest,
	};
});

export const hasAuthority = (value: string) => {
	const token = window.localStorage.getItem('token') || '{}';
	const authorities = JSON.parse(token).authorities;
	return includes(authorities, value);
};
export const isAdmin = hasAuthority(c.ADMIN);
export const isLoggedIn = window.localStorage.getItem('token');

const checkToken = memoize(token => {
	try {
		decode(token);
		return true;
	} catch (e) {
		console.error('fetch', e);
		return false;
	}
});

export const fetch = (
	url: string,
	options: RequestInit,
	params?: Object,
	context?: string,
) => {
	const tokenOptions = {
		checkToken,
		...options,
	};
	return customFetch(url, params ?? {}, tokenOptions, context, checkToken);
};

export type Dictionary<T extends unknown> = {
	[key: string]: T;
};
