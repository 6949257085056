import React from 'react';
import { Form } from 'semantic-ui-react';
import { Field, GenericField } from 'redux-form';

import * as T from './_checkboxTypes';

const View: React.FC<T.Checkbox> = (props: any) => (
	<Form.Checkbox
		error={props.meta.touched && props.meta.error}
		style={{ display: 'block', textAlign: 'left' }}
		onClick={(_, { checked }) => props.input.onChange(checked)}
		checked={!!props.input.value}
		{...props}
	/>
);
export const Checkbox = View;

export const FormCheckbox = Field as new () => GenericField<T.Checkbox>;
