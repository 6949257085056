import { sumBy, map, get } from 'lodash-es';
import { format } from 'date-fns';

import { TRANSACTION_TYPE_INCOME } from 'utils/constants';
import { fetch } from 'utils/utils';

export const getTransactions = async (): Promise<any> => {
	try {
		const response = await fetch(`/sarezaWeb/api/transaction/all`, {
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/json' }),
			body: JSON.stringify({
				start: 0,
				limit: 999,
				sorts: [{ property: 'transactionDate', direction: 'asc' }],
			}),
		});

		return response;
	} catch (err) {
		return false;
	}
};

export const sumTranactionsByType = (transactions: any, type: number) =>
	sumBy(transactions, (r: any) => {
		if (r.transactionType === type) {
			return r.amount;
		} else {
			return 0;
		}
	});

export const createChartDataset = (transactions: any) => {
	const dataset: Array<any> = [];
	const incomeAmount = (t: any) => t.amount;
	const outcomeAmount = (t: any) => t.amount * -1;
	const lastAmount = (d: Array<any>, i: number) => dataset[i - 1].amount;
	const isIncome = (t: any) => t.transactionType === TRANSACTION_TYPE_INCOME;
	const isFirst = (i: number) => i === 0;

	const pushToDataset = (transaction: any, index: number) => {
		dataset.push({
			transactionDate: format(
				get(transaction, 'transactionDate', ''),
				'dd.MM.yyyy HH:mm',
			),
			amount: isFirst(index)
				? incomeAmount(transaction)
				: lastAmount(dataset, index) +
				  (isIncome(transaction)
						? incomeAmount(transaction)
						: outcomeAmount(transaction)),
		});
	};

	map(transactions, (t: any, i: number) => pushToDataset(t, i));

	return dataset;
};
