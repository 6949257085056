export const BREADCRUMBS = 'BREADCRUMBS';
export const SET_TITLE = 'SET_TITLE';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const IS_LOADING = 'IS_LOADING';
export const SET_POPUP = 'SET_POPUP';
export const REGISTRATION = 'REGISTRATION';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_MODAL = 'SET_MODAL';
export const USER_CARDS = 'USER_CARDS';
export const USER_STORE = 'USER_STORE';

// authorities
export const ADMIN = 'admin';
export const CLIENT = 'user';

export const ROLES = [
	{ key: 'admin', name: 'Administrátor' },
	{ key: 'user', name: 'Klient' },
];

export const BREAKPOINTS = {
	mobileMax: 767,

	tabletMin: 768,
	tabletMax: 991,

	desktopMin: 992,
	desktopMax: 1200,

	largeMin: 1201,
};

export const TRANSACTION_TYPE_INCOME = 2;
export const TRANSACTION_TYPE_OUTCOME = 1;

export const CREATE = 'create';
export const ACTIVITY_ALL = 0;
export const PLACES_ALL = 0;

export const ENVELOPE_TYPE_GENERAL = 1;
export const ENVELOPE_TYPE_USER = 2;
export const ENVELOPE_TYPE_SUPPLIER = 3;
export const ENVELOPE_TYPE_EXPIRED = 4;
export const ENVELOPE_TYPE_DELETED = 5;

export const DIRECTION_DESC = 'desc';
export const DIRECTION_ASC = 'asc';
