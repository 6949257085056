import { get } from 'lodash-es';
import React from 'react';
import { reduxForm } from 'redux-form';
import * as queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { Button, Grid, Message, Form } from 'semantic-ui-react';
import { compose, withHandlers, withPropsOnChange, withState } from 'recompose';

import { FormPassword, Password } from 'components/form/input/Password';

import style from 'utils/style';
import { notEmpty } from 'utils/validate';

import { changeForgottenPassword } from './_forgottenPasswordActions';

const enhance = compose(
	withRouter,
	withState('loader', 'setLoader', false),
	withHandlers({
		onSubmit: (props: any) => async (values: any) => {
			const params = queryString.parse(get(props, 'location.search'));
			const token = get(params, 'token', '');

			props.setLoader(true);
			const response = await changeForgottenPassword({ ...values, token });
			props.setLoader(false);

			if (response) {
				props.history.push('/login');
			}
		},
	}),
	withPropsOnChange([], () => ({
		fields: { password1: 'Nové heslo', password2: 'Heslo znovu' },
	})),
	reduxForm({ form: 'changeForgottenPasswordForm' }),
);

const View: React.FC<any> = ({ msgs, handleSubmit, fields, loader }) => (
	<Grid textAlign="center" verticalAlign="middle" style={{ height: '100vh' }}>
		<Grid.Column
			mobile={14}
			tablet={7}
			computer={5}
			largeScreen={4}
			widescreen={3}
		>
			<Message attached>
				<Message.Header style={style('colors.text.primary')}>
					Obnova zapomenutého hesla
				</Message.Header>
			</Message>
			<Form
				size="small"
				className="attached fluid segment"
				loading={loader}
				onSubmit={handleSubmit}
			>
				{Object.keys(fields).map(fieldName => (
					<FormPassword
						key={fieldName + 'label'}
						name={fieldName}
						placeholder={get(fields, fieldName)}
						icon="lock"
						component={Password}
						validate={notEmpty}
					/>
				))}
				<Button content="Odeslat" color="teal" type="submit" />
			</Form>
		</Grid.Column>
	</Grid>
);

export const ChangeForgottenPassword = enhance(View);
