import React from 'react';
import { Form } from 'semantic-ui-react';
import { Field, GenericField } from 'redux-form';

import * as T from './_inputTypes';

const View: React.FC<T.FormInput> = (props: any) => (
	<Form.Input
		type="date"
		fluid
		icon="calendar"
		iconPosition="left"
		error={props.meta.touched && props.meta.error}
		{...props}
	/>
);
export const Date = View;

export const FormDate = Field as new () => GenericField<T.FormInput>;
