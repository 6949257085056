import { isString, isArray, get } from 'lodash-es';

import theme from '../styles/js/theme';

const style = (paths: any) => {
	if (isString(paths)) {
		return get(theme, paths, null);
	}
	if (isArray(paths)) {
		let output = {};
		paths.map(path => (output = { ...output, ...get(theme, path, {}) }));
		return output;
	}
	return null;
};

export default style;
