import React from 'react';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import classnames from 'classnames';
import MediaQuery from 'react-responsive';
import { getFormValues } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { Table, List, Icon, Button, Pagination } from 'semantic-ui-react';
import {
	get,
	isArray,
	identity,
	compact,
	concat,
	filter,
	omit,
	values,
	includes,
	without,
} from 'lodash-es';
import {
	compose,
	lifecycle,
	withState,
	onlyUpdateForKeys,
	withPropsOnChange,
	withStateHandlers,
} from 'recompose';

import { FormSelect, Select } from 'components/form/select/Select';

import {
	getActivities,
	getFacilities,
	getEnvelopes,
} from 'modules/envelopes/_envelopesActions';

import * as c from 'utils/constants';

const adapter = (array: Array<any>) =>
	concat(
		[
			{
				value: null,
				text: 'Vše',
				key: 'anything',
			},
		],
		compact(
			array.map((item: any, id: any) => ({
				value: item.id,
				text: item.name,
				key: id,
			})),
		),
	);

const enhance = compose<any, any>(
	withTranslation('Transactions'),
	withState<{}, boolean, string, string>('loader', 'setLoader', true),
	withState<{}, Array<any>, string, string>('activities', 'setActivities', []),
	withState<{}, Array<any>, string, string>('facilities', 'setFacilities', []),
	withState<{}, Array<any>, string, string>('envelopes', 'setEnvelopes', []),
	withState<{}, boolean, string, string>(
		'filtersVisible',
		'toggleFilters',
		false,
	),
	withStateHandlers<any, any, {}>(
		() => ({
			openedRows: [],
		}),
		{
			setOpenedRows: ({ openedRows }) => (rowId: any) => ({
				openedRows: includes(openedRows, rowId)
					? without(openedRows, rowId)
					: [...openedRows, rowId],
			}),
		},
	),
	lifecycle<any, any>({
		async componentDidMount() {
			const [activities, facilities, envelopes] = await Promise.all([
				getActivities(),
				getFacilities(),
				getEnvelopes(),
			]);
			if (isArray(activities)) {
				this.props.setActivities(adapter(activities));
			}
			if (isArray(facilities)) {
				this.props.setFacilities(adapter(facilities));
			}
			if (isArray(envelopes)) {
				this.props.setEnvelopes(adapter(envelopes));
			}
			this.props.setLoader(false);
		},
	}),
	withPropsOnChange(
		['activities', 'facilities', 'envelopes'],
		({ t, facilities, envelopes }: any) => ({
			tableCols: [
				// TODO: add sortable param
				{ id: 'transactionDate', label: t('DATE'), sortable: true },
				{
					id: 'facility',
					label: t('FACILITY'),
					filter: true,
					items: facilities,
					sortable: true,
				},
				{ id: 'place', label: t('PLACE'), sortable: true },
				{ id: 'account', label: t('ACCOUNT'), filter: true, items: envelopes },
				{
					id: 'amount',
					label: t('AMOUNT'),
					textAlign: 'right',
					sortable: true,
				},
				{ id: 'action', label: '' },
			],
		}),
	),
	connect((state: any) => {
		const filters = getFormValues('transactionsForm')(state);
		return {
			filtersCount: compact(values(omit(filters, ['from', 'to']))).length,
		};
	}),

	onlyUpdateForKeys([
		'transactions',
		'filtersVisible',
		'loader',
		'filtersCount',
		'openedRows',
		'activePage',
		'totalPages',
		'transactionsCount',
		'sort',
		'direction',
	]),
);

const View: React.FC<any> = ({
	transactions,
	tableCols,
	filtersVisible,
	toggleFilters,
	loader,
	filtersCount,
	setOpenedRows,
	openedRows,
	onPageChange,
	activePage,
	totalPages,
	setSorting,
	sort,
	direction,
	t,
}) => (
	<>
		<MediaQuery maxWidth={c.BREAKPOINTS.mobileMax}>
			{isMobileMax => (
				<>
					<div
						style={{
							display: 'flex',
							justifyContent: isMobileMax ? 'center' : 'flex-end',
							marginBottom: 16,
						}}
					>
						<Button
							icon
							labelPosition="left"
							onClick={() => toggleFilters(!filtersVisible)}
							color={filtersCount ? 'green' : undefined}
						>
							<Icon name="filter" />
							{filtersCount
								? `${t('FILTER')} (${filtersCount})`
								: t('FILTER_ACTION')}
						</Button>
					</div>
					{isMobileMax &&
						!loader &&
						filtersVisible &&
						filter(tableCols, 'filter').map((col: any) => (
							<div key={col.id}>
								<label htmlFor={col.id}>{col.label}</label>
								<FormSelect
									name={col.id}
									component={Select}
									options={col.items}
									valueFunction={identity}
									placeholder={t('ALL')}
									hideLabel
									style={{
										width: '100%',
										minWidth: 0,
										marginTop: 10,
										marginBottom: 10,
									}}
									fluid
								/>
							</div>
						))}
				</>
			)}
		</MediaQuery>
		<Table
			striped
			celled={false}
			padded
			relaxed="very"
			fixed
			attached
			color="teal"
			columns={3}
			selectable
		>
			<MediaQuery minWidth={c.BREAKPOINTS.tabletMin}>
				<Table.Header>
					<Table.Row>
						{tableCols.map((col: any) => (
							<Table.HeaderCell
								key={col.id}
								className={classnames('table--filter-header', {
									'table--clickable': col.sortable,
								})}
								textAlign={col.textAlign || null}
								onClick={() => (col.sortable ? setSorting(col.id) : false)}
							>
								{col.label}
								{sort === col.id && (
									<Icon
										name={
											direction === c.DIRECTION_DESC ? 'caret down' : 'caret up'
										}
									/>
								)}
								{!loader && col.filter && filtersVisible && (
									<FormSelect
										name={col.id}
										component={Select}
										options={col.items}
										valueFunction={identity}
										placeholder={t('ALL')}
										hideLabel
										style={{ width: '100%', minWidth: 0, marginTop: 10 }}
									/>
								)}
							</Table.HeaderCell>
						))}
					</Table.Row>
				</Table.Header>
			</MediaQuery>

			<Table.Body>
				{isArray(transactions) && transactions.length ? (
					transactions.map((transaction: any, index: number) => {
						const items = get(transaction, 'items', []);
						const rowIsOpened = includes(openedRows, transaction.id);
						const isIncome =
							transaction.transactionType === c.TRANSACTION_TYPE_INCOME;
						const isOutcome =
							transaction.transactionType === c.TRANSACTION_TYPE_OUTCOME;
						return (
							<React.Fragment key={index}>
								<Table.Row onClick={() => setOpenedRows(transaction.id)}>
									<Table.Cell>
										<MediaQuery maxWidth={c.BREAKPOINTS.mobileMax}>
											{t('DATE')}:{' '}
										</MediaQuery>
										{format(
											new Date(get(transaction, 'transactionDate', '')),
											'dd.MM.yyyy HH:mm',
										)}
									</Table.Cell>
									<Table.Cell>
										<MediaQuery maxWidth={c.BREAKPOINTS.mobileMax}>
											{t('FACILITY')}:{' '}
										</MediaQuery>
										{get(transaction, 'facility.name', '')}
									</Table.Cell>
									<Table.Cell>
										<MediaQuery maxWidth={c.BREAKPOINTS.mobileMax}>
											{t('PLACE')}:{' '}
										</MediaQuery>
										{get(transaction, 'facility.description')}
									</Table.Cell>
									<Table.Cell>
										<MediaQuery maxWidth={c.BREAKPOINTS.mobileMax}>
											{t('ACCOUNT')}:{' '}
										</MediaQuery>
										{get(transaction, 'account.name', '')}
									</Table.Cell>
									<Table.Cell
										positive={isIncome || !isOutcome}
										negative={isOutcome}
										textAlign="right"
									>
										{isOutcome && '-'}
										{transaction.amount}
									</Table.Cell>
									<Table.Cell textAlign="right">
										<Icon
											name={rowIsOpened ? 'caret up' : 'caret down'}
											circular
											style={{ cursor: 'pointer' }}
										/>
									</Table.Cell>
								</Table.Row>
								{rowIsOpened && isArray(items) && items.length ? (
									<Table.Row>
										<Table.Cell colSpan="4" textAlign="right">
											{items.map((item: any) => (
												<List.Item as="div" key={`item-1-${item.id}`}>
													<List.Content>
														<List.Header>
															{get(item, 'activity.name', '')}
														</List.Header>
													</List.Content>
												</List.Item>
											))}
										</Table.Cell>
										<Table.Cell textAlign="right">
											<List>
												{items.map((item: any) => (
													<List.Item as="div" key={`item-2-${item.id}`}>
														<List.Content>
															<List.Header>
																{isOutcome && '-'}
																{get(item, 'amount', '')}
															</List.Header>
														</List.Content>
													</List.Item>
												))}
											</List>
										</Table.Cell>
										<Table.Cell />
									</Table.Row>
								) : null}
							</React.Fragment>
						);
					})
				) : (
					<Table.Row>
						<Table.Cell>{t('NO_DATA')}</Table.Cell>
					</Table.Row>
				)}
			</Table.Body>

			<Table.Footer>
				<Table.Row>
					<Table.HeaderCell colSpan="6">
						<Pagination
							activePage={activePage}
							totalPages={totalPages}
							onPageChange={onPageChange}
						/>
					</Table.HeaderCell>
				</Table.Row>
			</Table.Footer>
		</Table>
	</>
);

export const TransactionsTable = enhance(View);
