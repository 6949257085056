import * as c from './index';

export default [
	{ link: '/account/envelopes', label: 'PROFILE', restriction: null },
	{ link: '/account/transactions', label: 'TRANSACTIONS', restriction: null },
	// {
	//   label: 'TRANSACTIONS',
	//   restriction: null,
	//   submenu: [{ link: '/account/transactions', label: 'HISTORY' }, { link: '/account/trend', label: 'TREND' }]
	// },
	{ link: '/cards/my-cards', label: 'CARDS', restriction: null },
	{ link: '/facilities/all', label: 'FACILITIES', restriction: null },
	{ link: '/news/my-news', label: 'NEWS', restriction: null },
	{ link: '/admin', label: 'ADMINISTRATION', restriction: c.ADMIN },
];
