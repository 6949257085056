import appBar from './components/appBar';
import common from './components/common';
import colors from './components/colors';
import button from './components/button';

export default {
  appBar,
  common,
  colors,
  button,
};
