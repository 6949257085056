import { groupBy } from 'lodash-es';

import { ALL_PLACES_KEY } from 'modules/facilities/_facilitiesConstants';

import { fetch } from 'utils/utils';

import * as T from './_facilitiesTypes';

export const getAllFacilities = async (): Promise<T.Area[]> => {
	const response = (await fetch(
		`https://www.sareza.cz/wp-json/sareza/api-long?token=v9sfz7PspJvtpgpIkTKpTnJIdWxuSPyS`,
		{
			method: 'GET',
			headers: new Headers({ 'Content-Type': 'application/json' }),
		},
	)) as T.FacilityResponse;

	return response.areas;
};

export const getFacility = async (id: number): Promise<T.Facility> => {
	return await fetch(`/sarezaWeb/api/facility/${id}`, {
		method: 'GET',
		headers: new Headers({ 'Content-Type': 'application/json' }),
	});
};

// Filter by activity
export const filterByActivity = (allActivity: string) => (activity: string) => (
	facilities: T.Area[],
): T.Area[] => {
	if (activity === allActivity) {
		return facilities;
	}

	return facilities.filter(facility =>
		facility.activities.find(
			facilityActivity =>
				facilityActivity && facilityActivity.color === activity,
		),
	);
};

// Filter by location
export const filterByLocation = (place: string) => (
	facilities: T.Area[],
): T.Area[] => {
	if (place === ALL_PLACES_KEY) {
		return facilities;
	}

	return facilities.filter(facility => facility.location === place);
};

// Group activities by type of activity
export const groupActivitiesByType = (
	facilities: T.Area[],
): T.AreaWithGroupedActivities[] =>
	facilities.map(facility => ({
		...facility,
		groupedActivities: groupBy<T.ActivityItem>(facility.activities, 'color'),
	}));

// // Filter facilities by place
// export const filterByPlace = (place: number | string) => (
//   facilities: T.Facility[]
// ): T.Facility[] =>
//   place === c.PLACES_ALL
//     ? facilities
//     : facilities.filter(facility => facility.description === place);
//
// // Remove acitivity 'ALL' from facilities
// export const removeAllActivitiesFromFacilities = (
//   facilities: T.Facility[]
// ): T.Facility[] =>
//   facilities.map(facility => ({
//     ...facility,
//     activities: facility.activities.filter(
//       facilityActivity => facilityActivity.id !== c.ACTIVITY_ALL
//     )
//   }));
//
