import React from 'react';
import { Message } from 'semantic-ui-react';
import { defaultProps } from 'recompose';

const Messages = ({ msgs, style }: any) => (
	<div style={{ ...style }}>
		{msgs.map((msg: any, i: number) => (
			<Message
				key={i}
				content={msg.msg}
				negative={msg.code === 'ERROR' ? true : false}
				info={msg.code === 'INFO' ? true : false}
			/>
		))}
	</div>
);

export default defaultProps({
	msgs: [],
	style: {
		marginBottom: 0,
	},
})(Messages);
