import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FormSection, reduxForm, getFormValues } from 'redux-form';
import { get } from 'lodash-es';
import { compose, withHandlers } from 'recompose';
import { Grid, Button } from 'semantic-ui-react';

import { FormPassword, Password } from 'components/form/input/Password';
import PasswordStrength from 'components/form/passwordStrength/PasswordStrength';

import { basicFormValidator, notEmpty } from 'utils/validate';

import { changePassword } from './_profileActions';

const enhance = compose<any, any>(
	withTranslation('Profile'),
	withHandlers({
		onSubmit: (props: any) => async (values: any) => {
			const response = await changePassword(values.password);

			if (get(response, 'msgs')) {
				get(response, 'msgs').map((message: any) =>
					message.code === 'INFO'
						? props.setMessage({
								positive: true,
								visible: true,
								header: props.t(message.msg),
						  })
						: props.setMessage({
								negative: true,
								visible: true,
								header: props.t(message.msg),
						  }),
				);
			}
		},
	}),
	reduxForm({ form: 'registrationForm', validate: basicFormValidator }),
	connect(state => {
		const values = getFormValues('registrationForm')(state);
		return {
			password: get(values, 'password1'),
		};
	}),
);

const View: React.FC<any> = ({ password, t, handleSubmit }) => (
	<Grid columns={1}>
		<Grid.Row>
			<Grid.Column mobile={16} computer={8} widescreen={6}>
				<FormSection name={'password'}>
					{[
						{
							name: 'oldPassword',
							label: t('OLD_PASSWORD'),
							validate: notEmpty,
						},
						{
							name: 'password1',
							label: t('NEW_PASSWORD'),
							validate: notEmpty,
							matchPasswords: true,
						},
						{
							name: 'password2',
							label: t('NEW_PASSWORD_AGAIN'),
							validate: notEmpty,
							matchPasswords: true,
						},
					].map(field => (
						<FormPassword
							{...field}
							width={10}
							placeholder=""
							key={field.name}
							component={Password}
						/>
					))}
					<div style={{ marginBottom: 15 }}>
						<PasswordStrength password={password} />
					</div>
				</FormSection>
				<div style={{ marginTop: 20 }}>
					<Button
						color="teal"
						onClick={handleSubmit}
						content={t('SAVE_PROFILE')}
					/>
				</div>
			</Grid.Column>
		</Grid.Row>
	</Grid>
);

export default enhance(View);
