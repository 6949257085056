export default {
	Profile: {
		CHANGE_PASSWORD: 'Change password',
		OLD_PASSWORD: 'Old password',
		NEW_PASSWORD: 'New password',
		NEW_PASSWORD_AGAIN: 'New password (again)',
		SAVE_PASSWORD: 'Save password',
		SAVE_PROFILE: 'Save profile',
		USER_PROFILE: 'User profile',
	},
};
