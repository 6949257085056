export const activities = [
	{
		value: 'vse',
		text: 'Vše',
	},
	{
		value: 's-vodou',
		text: 'S vodou',
	},
	{
		value: 'bez-vody',
		text: 'Bez vody',
	},
	{
		value: 'relax',
		text: 'Relax',
	},
];

export const ALL_PLACES_KEY = 'vse';
