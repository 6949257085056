import { fetch } from 'utils/utils';

import * as T from './_cardsTypes';

export const getAll = async (): Promise<Array<T.Card>> => {
	return await fetch(`/sarezaWeb/api/card/all`, {
		method: 'POST',
		headers: new Headers({ 'Content-Type': 'application/json' }),
		body: JSON.stringify({
			start: 0,
			limit: 1000,
		}),
	});
};

export const putCard = async (card: T.Card) => {
	return await fetch(`/sarezaWeb/api/card/${card.id}`, {
		method: 'PUT',
		headers: new Headers({ 'Content-Type': 'application/json' }),
		body: JSON.stringify(card),
	});
};
