import React from 'react';
import { get } from 'lodash-es';
import { withTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';
import { compose } from 'recompose';

const enhance = compose<any, any>(withTranslation('Facilities'));

const View: React.FC<any> = ({
	close,
	id,
	text,
	deleteAndClose,
	envelope,
	t,
}) => (
	<Modal
		size="tiny"
		open
		onClose={() => close('confirm')}
		closeOnEscape={false}
	>
		<Modal.Header>{t('DELETE')}</Modal.Header>
		<Modal.Content>{text}</Modal.Content>
		<Modal.Actions>
			<Button
				color="orange"
				onClick={() => close('confirm')}
				content={t('NO')}
			/>
			<Button
				positive
				onClick={() => deleteAndClose(id)}
				icon="checkmark"
				labelPosition="right"
				content={t('YES')}
				disabled={get(envelope, 'balanceAmount') > 0}
			/>
		</Modal.Actions>
	</Modal>
);

export const Confirm = enhance(View);
