import React from 'react';
import { Message } from 'semantic-ui-react';

export const SuccessfulMessage: React.FC<{
	enable: boolean;
	title: string;
	description: string;
}> = ({ enable = true, title, description }) => {
	if (!enable) {
		return null;
	}

	return (
		<Message positive>
			<Message.Header>{title}!</Message.Header>
			<p>{description}</p>
		</Message>
	);
};
