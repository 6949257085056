import { isEmpty, trimEnd } from 'lodash-es';

import * as storage from './storage';

export const serialize = (obj = {}) =>
	Object.keys(obj)
		.map(
			key =>
				`${encodeURIComponent(key)}=${encodeURIComponent((obj as any)[key])}`,
		)
		.join('&');

export const json = async (
	fn: Function,
	...args: Array<any>
): Promise<Object> => {
	const response = await fn(...args);

	return response.json();
};

const bearerResponse = async (response: Promise<Object>) => {
	return await response.then(async (data: any) => {
		try {
			const jsonResponse = await data.json();

			if (jsonResponse.sessionId) {
				const todoToken = JSON.stringify(jsonResponse);

				storage.put('token', todoToken);
			}

			return jsonResponse;
		} catch (e) {
			return data;
		}
	});
};

const handleUnauthorized = async () => {
	console.log('handleUnauthorized - 401');

	await fetch(`/sarezaWeb/api/sec/logout`, {
		method: 'POST',
		headers: new Headers({ 'Content-Type': 'application/json' }),
	});

	storage.remove('token');

	window.location.reload();
};

const handleErrors = async (response: any) => {
	const r = await response;

	if (r.status === 401 && storage.get('token')) {
		handleUnauthorized();
	}

	if (!r.ok) {
		throw new Error(r.status);
	}
	return response;
};

export const defaultOptions: RequestInit = {
	credentials: 'same-origin',
	headers: new Headers(),
};

export default (
	url: string,
	params: Object,
	options?: RequestInit,
	context?: string,
	checkToken?: (_: string) => void,
) => {
	// const token = storage.get('token');
	const headers = options?.headers;

	// if (token && token !== 'null' && checkToken(token)) {
	/* tslint:disable:no-string-literal */
	// headers['Authorization'] = `Bearer ${token}`;
	// headers['Authorizationjwt'] = `Bearer ${token}`;
	/* tslint:enable:no-string-literal */
	// }

	const queryString = serialize(params);
	const questionMark = isEmpty(queryString) ? '' : '?';

	return bearerResponse(
		handleErrors(
			fetch(`${trimEnd(context, '/')}${url}${questionMark}${queryString}`, {
				...defaultOptions,
				...options,
				headers,
			}),
		),
	);
};
