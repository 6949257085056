import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import cs from './languages/cs';
import en from './languages/en';

i18n.use(initReactI18next).init({
	// we init with resources
	resources: {
		en,
		cs,
	},
	fallbackLng: 'cs',
	debug: true,

	// have a common namespace used around the full app
	ns: ['translations'],
	defaultNS: 'translations',

	keySeparator: false, // we use content as keys

	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
