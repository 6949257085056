export default {
  user: {
    logout: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    name: {
      textDecoration: 'underline',
      color: 'white',
    },
    overview: {
      fontSize: 12,
      lineHeight: '130%',
    },
    role: {
      cursor: 'default',
    },
  },
};
