import React from 'react';
import { keys, get, findIndex } from 'lodash-es';
import * as queryString from 'query-string';
// import { keys, includes, without } from 'lodash-es';
import { getFormSyncErrors } from 'redux-form';
import { withTranslation } from 'react-i18next';
import {
	Header,
	Grid,
	Tab,
	Divider,
	Menu,
	Icon,
	Message,
	Transition,
} from 'semantic-ui-react';
import {
	compose,
	withState,
	withPropsOnChange,
	withHandlers,
	withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';

import * as T from './_adminTypes';

// import ProfileBasicForm from './ProfileBasicForm';
// import ProfileAddressForm from './ProfileAddressForm';

import { Facilities } from './facilities/Facilities';

const enhance = compose(
	withTranslation('Admin'),
	withState<{}, boolean, string, string>('loader', 'setLoader', false),
	withStateHandlers(
		() => ({
			message: {
				visible: false,
			},
		}),
		{
			setMessage: () => (message: any) => ({
				message,
			}),
		},
	),
	connect(state => {
		const formSyncErrors = getFormSyncErrors('profileForm')(state);
		return {
			formSyncErrors,
			formHasErrors: keys(formSyncErrors).length,
		};
	}),
	/*
  lifecycle<any, any>({
    async componentDidMount() {
      const { initialize } = this.props;
      const response = await getProfile();
      initialize(get(response, 'data'));
    }
  }),
  */
	withHandlers({
		renderTabButton: ({ t }: any) => (title: string, hasError: boolean) => (
			<Menu.Item key={title}>
				<span style={{ color: hasError ? '#db2828' : 'inherit' }}>
					{t(title)}
				</span>{' '}
				{hasError ? (
					<Icon color="red" name="warning circle" style={{ marginLeft: 10 }} />
				) : null}
			</Menu.Item>
		),
	}),
	withPropsOnChange(
		['formSyncErrors', 'submitFailed'],
		({ renderTabButton, formSyncErrors, submitFailed, setSame, t }: any) => {
			// const tabsWithErros = keys(formSyncErrors);
			return {
				panes: [
					{
						key: 'users',
						menuItem: renderTabButton('USERS', submitFailed), // && without(tabsWithErros, 'accounts', 'cards').length),
						pane: (
							<Tab.Pane
								style={{ border: 0, padding: 0, background: 'transparent' }}
							>
								<p>USERS</p>
							</Tab.Pane>
						),
					},
					{
						key: 'accounts',
						menuItem: renderTabButton('ACCOUNTS', submitFailed), // && includes(tabsWithErros, 'accounts')),
						pane: (
							<Tab.Pane
								style={{ border: 0, padding: 0, background: 'transparent' }}
							>
								<p>ACCOUNTS</p>
							</Tab.Pane>
						),
					},
					{
						key: 'cards',
						menuItem: renderTabButton('CARDS', submitFailed), // && includes(tabsWithErros, 'cards')),
						pane: (
							<Tab.Pane
								style={{ border: 0, padding: 0, background: 'transparent' }}
							>
								<p>CARDS</p>
							</Tab.Pane>
						),
					},
					{
						key: 'facilities',
						menuItem: renderTabButton('AREALS', submitFailed), // && includes(tabsWithErros, 'areals')),
						pane: (
							<Tab.Pane
								style={{ border: 0, padding: 0, background: 'transparent' }}
							>
								<Facilities />
							</Tab.Pane>
						),
					},
					{
						key: 'paymentTypes',
						menuItem: renderTabButton('PAYMENT_TYPES', submitFailed), // && includes(tabsWithErros, 'paymentTypes')),
						pane: (
							<Tab.Pane
								style={{ border: 0, padding: 0, background: 'transparent' }}
							>
								<p>PAYMENT_TYPES</p>
							</Tab.Pane>
						),
					},
					{
						key: 'groupOfActivities',
						menuItem: renderTabButton('GROUP_OF_ACTIVITIES', submitFailed), // && includes(tabsWithErros, 'groupOfActivities')),
						pane: (
							<Tab.Pane
								style={{ border: 0, padding: 0, background: 'transparent' }}
							>
								<p>GROUP_OF_ACTIVITIES</p>
							</Tab.Pane>
						),
					},
				],
			};
		},
	),
	withPropsOnChange([], ({ panes }: any) => {
		/* eslint-disable-next-line no-restricted-globals */
		const activeTab = get(queryString.parse(location.search), 'activeTab');
		const activeTabIndex = findIndex(panes, { key: activeTab });
		return {
			activeTabIndex: activeTabIndex > -1 ? activeTabIndex : 0,
		};
	}),
);

const View: React.FC<T.Admin> = ({
	message,
	setMessage,
	panes,
	activeTabIndex,
	t,
}) => (
	<>
		<Grid celled={false} padded="horizontally">
			<Divider hidden />
			<Grid.Row>
				<Grid.Column width={12}>
					<Transition
						visible={message.visible}
						animation="fade down"
						duration={500}
					>
						<Message
							onDismiss={() => setMessage({ ...message, visible: false })}
							{...message}
						/>
					</Transition>
					<Header as="h1">{t('ADMINISTRATION')}</Header>
					<Tab
						panes={panes}
						menu={{ secondary: true, pointing: true, color: 'teal' }}
						renderActiveOnly={false}
						defaultActiveIndex={activeTabIndex}
					/>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	</>
);

export const Admin = enhance(View as any);
